import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HTMLEditor = ({ className = '', value = '', onChange, modules }) => {
  const isIterable = Array?.isArray(modules);
  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link', { list: 'bullet' }],
    ...(isIterable ? modules : [])
  ];
  return (
    <ReactQuill
      theme="snow"
      className={className}
      value={value}
      onChange={onChange}
      modules={{ toolbar: toolbarOptions }}
    />
  );
};

export default HTMLEditor;
