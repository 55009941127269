import { useQuery } from '@apollo/client';
import { Tag } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import rightArrow from '../../assets/svg/right-arrow.svg';
import { ROUTES } from '../../common/constants';
import CommonUpload from '../../components/CommonUpload';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_APP } from './graphql/Queries';
import './style/uploadRelease.less';

const UploadRelease = () => {
  const appID = useParams();
  const history = useHistory();
  const id = appID?.appId;

  const { data: appData, loading } = useQuery(GET_APP, {
    fetchPolicy: 'network-only',
    variables: {
      appId: id
    }
  });

  const handleSkip = () => {
    history?.push(`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}`);
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="release-container">
      <div>
        <div className="release-content mb-24">
          <span className="release-app-name font-24">
            {appData?.getApplication?.appName}
            <Tag>New Release</Tag>
          </span>
          <span onClick={handleSkip} className="pointer">
            Skip <img src={rightArrow} alt="right-arrow" className="ml-7" />
          </span>
        </div>
      </div>
      <CommonUpload id={id} />
    </div>
  );
};

export default UploadRelease;
