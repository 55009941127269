import { gql } from '@apollo/client';

export const GET_APP_DETAIL = gql`
  query getApp($getAppInput: GetAppInput!) {
    getApp(data: $getAppInput) {
      appData {
        id
        applicationUniqueId
        appPackageName
        appVersion
        appBuild
        appSize
        appStorageUrl
        plistStorageUrl
        appMetadata
        releaseNote
        appName
        appLogo
      }
      isPrivate
      status
    }
  }
`;

export const GET_CHECK_APP_VISIBILITY = gql`
  query checkAppVisibility($getCheckAppInput: CheckAppVisibilityInput!) {
    checkAppVisibility(data: $getCheckAppInput) {
      isPrivate
      applicationDetails {
        id
        appName
        appLogo
      }
      disableAds
    }
  }
`;
