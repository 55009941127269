import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USER = gql`
  query user {
    user {
      id
      firstName
      lastName
      email
      profileImage
      defaultWorkspace
      message
    }
  }
`;
