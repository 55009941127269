import {
  DeleteFilled,
  EllipsisOutlined,
  LeftOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import defaultIcon from '../assets/svg/default-app.svg';
import { ACTIONS, ROUTES } from '../common/constants';
import DeleteApp from '../modules/apps/Components/DeleteAppModal';
import { DELETE_APPLICATION } from '../modules/apps/graphql/Mutations';
import { GET_APP } from '../modules/apps/graphql/Queries';
import '../modules/apps/style/appNameHeader.less';
import AccessControl from './AccessControl';
import LoaderComponent from './LoaderComponent';

const { Header } = Layout;

const AppNameHeader = ({ refetchData, setRefetchData }) => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const appID = useParams();
  const id = appID?.appId;
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [deleteAppModal, setDeleteAppModal] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const [getAppDetails, { data }] = useLazyQuery(GET_APP, {
    fetchPolicy: 'network-only',
    onError() {}
  });

  useEffect(() => {
    getAppDetails({
      variables: {
        appId: id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refetchData) {
      getAppDetails({
        variables: {
          appId: id
        }
      });
      setRefetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchData]);

  const BackToApps = () => {
    if (location?.pathname?.includes(ROUTES?.APP_SETTINGS)) {
      history?.goBack();
    } else {
      history?.push(ROUTES?.APPS);
    }
  };

  const [deleteAppMutation] = useMutation(DELETE_APPLICATION, {
    variables: {
      appId: id
    },
    onCompleted() {
      setBtnLoading(false);
      BackToApps();
    },
    onError() {
      setBtnLoading(false);
    }
  });

  if (btnLoading) {
    <LoaderComponent />;
  }

  const menu = (
    <Menu className="app-name-header">
      <Menu.Item key="app-settings">
        <Link
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/${id}`}
        >
          <SettingOutlined className="mr-8" />
          App Settings
        </Link>
      </Menu.Item>
      {(AccessControl({ allowedPermissions: ACTIONS?.DELETE_APP }) ||
        data?.getApplication?.appCreatedBy === currentUser?.id) && (
        <>
          <Menu.Divider className="menu-header-divider" />
          <Menu.Item
            danger
            key="deleteApp"
            onClick={() => setDeleteAppModal(true)}
          >
            <DeleteFilled className="mr-8" />
            Delete App
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <>
      <Header className="app-name-header">
        <div className="app-name-header-layout d-flex justify-center align-center">
          <div className="app-name-header-left">
            <LeftOutlined className="mr-25" onClick={BackToApps} />
            <Avatar
              src={data?.getApplication?.appLogo || defaultIcon}
              alt="application-img"
              className="avatar-width"
              size={28}
            />
            <span
              className="header-app-name font-16"
              title={data?.getApplication?.appName}
            >
              {data?.getApplication?.appName}
            </span>
          </div>

          <div className="app-name-header-middle">
            <Menu
              mode="horizontal"
              selectedKeys={setLocation?.[3]}
              className="d-flex justify-center"
            >
              <Menu.Item key="releases">
                <Link
                  to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}`}
                >
                  Releases
                </Link>
              </Menu.Item>

              {AccessControl({
                allowedPermissions: ACTIONS?.UPDATE_APP_SERVICE
              }) && (
                <Menu.Item key="apps-services">
                  <Link
                    to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/${id}`}
                  >
                    App Services
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="app-share">
                <Link
                  to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/${id}`}
                >
                  Members
                </Link>
              </Menu.Item>
            </Menu>
          </div>

          <div className="app-name-header-right">
            <Dropdown
              overlayClassName="app-menu"
              overlay={menu}
              trigger={['click']}
              placement="topRight"
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <EllipsisOutlined rotate={90} />
            </Dropdown>
          </div>
        </div>
      </Header>

      <DeleteApp
        showDeleteAppModal={deleteAppModal}
        handleVisible={setDeleteAppModal}
        mutation={deleteAppMutation}
        deleteAppData={data}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default AppNameHeader;
