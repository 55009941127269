import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { TEAM_KEY } from '../../common/constants';
import GroupsList from './Components/GroupsList';
import MembersList from './Components/MembersList';
import './style/teams.less';

const Teams = () => {
  // eslint-disable-next-line no-undef
  const teamId = localStorage?.getItem(TEAM_KEY);
  const [updateTeamsComp, setUpdateTeamsComp] = useState(false);

  return (
    <div className="teams">
      <Row className="w-full" gutter={[30, 30]}>
        <Col md={24} lg={24} xl={12} xxl={12}>
          <MembersList
            teamId={teamId}
            updateTeamsComp={updateTeamsComp}
            setUpdateTeamsComp={setUpdateTeamsComp}
          />
        </Col>
        <Col md={24} lg={24} xl={12} xxl={12}>
          <GroupsList
            teamId={teamId}
            setUpdateTeamsComp={setUpdateTeamsComp}
            updateTeamsComp={updateTeamsComp}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Teams;
