import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { MODAL_WIDTH } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import '../style/appDetails.less';

const ViewAllShareLinks = ({
  showShareLinkModal,
  handleVisible,
  data,
  version,
  selectedPlatform,
  columns,
  loading,
  refetchTableData
}) => {
  const handleCancel = () => {
    if (refetchTableData) {
      refetchTableData();
    }
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        width={MODAL_WIDTH}
        centered
        title={
          <span>
            {selectedPlatform === 'IOS' ? (
              <AppleFilled size={18} className="ios-color" />
            ) : (
              <AndroidFilled className="android-color" />
            )}
            {`Version ${version}`}
          </span>
        }
        footer={null}
        onCancel={handleCancel}
        visible={showShareLinkModal}
        wrapClassName="shared-link-modal"
      >
        <span>
          {data && (
            <CommonTable
              className="modal-content"
              columns={columns}
              loading={loading}
              data={data?.getShareableLinks}
              rowKey={(obj) => obj?.id}
            />
          )}
        </span>
      </Modal>
    </div>
  );
};

export default ViewAllShareLinks;
