import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message
} from 'antd';
import axios from 'axios';
import { trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import editIcon from '../../assets/images/edit-icon.png';
import {
  ALL_IMAGE_UPLOAD,
  GA_EVENT,
  GA_LABEL,
  REGEX
} from '../../common/constants';
import {
  formValidatorRules,
  googleAnalyticsEvents,
  previewImage
} from '../../common/utils';
import { GET_SIGNED_URL } from '../onboard/graphql/mutation';
import { UPDATE_USER } from './graphql/Mutations';
import { GET_USER_TEAM } from './graphql/Queries';
import './profilesettings.less';

const { required } = formValidatorRules;

const ProfileSettings = () => {
  const {
    state: { currentUser },
    dispatch
  } = useContext(AppContext);

  const [form] = Form?.useForm();

  const [currentFile, setCurrentFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState([]);

  const { data: workspaceData, loading: workspaceLoading } = useQuery(
    GET_USER_TEAM,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [initialValue] = useState({
    firstName: currentUser?.firstName ? trim(currentUser?.firstName) : '',
    lastName: currentUser?.lastName ? trim(currentUser?.lastName) : '',
    email: currentUser?.email,
    defaultWorkspace: currentUser?.defaultWorkspace
  });

  useEffect(() => {
    if (currentUser?.profileImage) {
      setImageUpload([{ url: currentUser?.profileImage }]);
    } else {
      setImageUpload([]);
    }
  }, [currentUser]);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      setBtnLoading(false);
    },
    onError() {
      setBtnLoading(false);
    }
  });

  const [state, setState] = useState({
    selectedImage: {},
    uploading: false
  });

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    }
  });

  const { selectedImage } = state;

  const handleUpload = async (value) => {
    const { firstName, lastName, defaultWorkspace } = value;
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true
    });

    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type
          }
        }
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read'
        }
      };

      try {
        await axios?.put(
          res?.data?.getSignedUrl?.signedURL,
          currentFile,
          config
        );
        const data = {
          firstName,
          lastName,
          profileImage: res?.data?.getSignedUrl?.fileUrl,
          defaultWorkspace
        };
        const userData = await updateUser({
          variables: {
            data
          }
        });
        if (userData?.data?.updateUser) {
          dispatch({
            type: 'SET_CURRENT_USER',
            data: userData?.data?.updateUser
          });
          setBtnLoading(false);
        }
        googleAnalyticsEvents(GA_EVENT?.USER_PROFILE_EDIT, {
          label: GA_LABEL?.USER_PROFILE_EDIT,
          first_name: firstName,
          last_name: lastName,
          profile_image: res?.data?.getSignedUrl?.fileUrl
        });
      } catch (err) {
        return err;
      }
    } else {
      const userData = await updateUser({
        variables: {
          data: {
            firstName,
            lastName,
            profileImage: imageUpload?.[0]?.url || null,
            defaultWorkspace
          }
        }
      });
      googleAnalyticsEvents(GA_EVENT?.USER_PROFILE_EDIT, {
        label: GA_LABEL?.USER_PROFILE_EDIT,
        first_name: firstName,
        last_name: lastName,
        profile_image: imageUpload?.[0]?.url || null
      });
      if (userData?.data?.updateUser) {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: userData?.data?.updateUser
        });
        setBtnLoading(false);
      }
    }
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url }
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);
    } else if (info?.file?.status === 'removed') {
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  return (
    <div className="profile-card">
      <div className="card-content">
        <Card
          title="Profile Settings"
          bordered
          className="card-title"
          loading={workspaceLoading}
        >
          <Form
            name="profile-settings"
            layout="vertical"
            form={form}
            onFinish={handleUpload}
            initialValues={initialValue}
          >
            <Form.Item name="profileImage">
              <div className="profile-upload">
                <Upload
                  maxCount={1}
                  onChange={onChangeImageUpload}
                  previewFile={previewImage}
                  fileList={imageUpload}
                  beforeUpload={() => {
                    return false;
                  }}
                  listType="picture-card"
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {!imageUpload?.length && (
                    <img src={editIcon} alt="icon" className="icon-image" />
                  )}
                </Upload>
                <span className="profile-heading font-12">
                  Update Profile Image
                </span>
              </div>
            </Form.Item>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      ...required,
                      whitespace: true,
                      message: 'Enter a valid First Name'
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid First Name'
                    }
                  ]}
                >
                  <Input placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      ...required,
                      whitespace: true,
                      message: 'Enter a valid Last Name'
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid Last Name'
                    }
                  ]}
                >
                  <Input placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="email" label="Email Id">
              <Input disabled />
            </Form.Item>
            <Form.Item name="defaultWorkspace" label="Default WorkSpace">
              <Select
                placeholder="Select Default WorkSpace"
                name="defaultWorkspace"
                loading={workspaceLoading}
              >
                {workspaceData?.getUserTeams?.map((team) => (
                  <Select.Option value={team?.id} key={team?.id}>
                    {team?.teamName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Divider className="card-divider" />
            <Row className="button-row">
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                loading={btnLoading}
                className="save-btn"
              >
                Save
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ProfileSettings;
