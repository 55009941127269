import { Button, Card, Layout, message } from 'antd';
import { isIOS } from 'mobile-device-detect';
import QRcode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo-big.png';
import {
  AppStoreDownload,
  MobileAppStoreDownload,
  MobilePlayStoreDownload,
  PlayStoreDownload,
  UdidError,
  UdidErrorMobile,
  UdidStart,
  UdidSuccess
} from '../../assets/svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import {
  APPSONAIR_MAIN_LINK,
  DOWNLOAD_APPSTORE_URL,
  DOWNLOAD_PLAYSTORE_URL
} from '../../common/constants';
import { copyToClipboard } from '../../common/utils';
import AdsComponent from '../../components/AdsComponent';
import CommonFooter from '../../components/CommonFooter';
import './detail.less';

const { Header, Content } = Layout;

const IosDeviceUdid = () => {
  const location = useLocation();
  const [udid, setUdid] = useState(null);
  const [product, setProduct] = useState(null);
  const [version, setVersion] = useState(null);
  // eslint-disable-next-line no-undef
  const currentURL = window?.location?.href;
  // eslint-disable-next-line no-undef
  const isMobileScreen = !(window?.innerWidth > 575);
  // eslint-disable-next-line no-undef
  const isTabletScreen = !(window?.innerWidth > 768);
  // eslint-disable-next-line no-undef
  const isLessThan300 = !(window?.innerWidth > 350);
  const linkRef = useRef(null);

  // Function to trigger the click after 1 second
  const triggerClickAfterOneSecond = () => {
    setTimeout(() => {
      linkRef?.current?.click();
    }, 1000);
  };

  const removeQueryParamsFromUrl = () => {
    const urlWithoutParams =
      // eslint-disable-next-line no-undef
      window?.location?.origin + window?.location?.pathname;
    // eslint-disable-next-line no-undef
    window?.history?.replaceState(null, '', urlWithoutParams);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    setUdid(queryParams?.get('udid'));
    setProduct(queryParams?.get('product'));
    setVersion(queryParams?.get('version'));

    // Added timeout because of getting data delay in mobile side.
    // Remove query parameters from the URL
    setTimeout(() => {
      removeQueryParamsFromUrl();
    }, 5000);
  }, [location?.search]);

  const copyUdid = () => {
    copyToClipboard(udid);
    message?.success('UDID copied to clipboard');
  };

  const copyUrl = () => {
    copyToClipboard(currentURL);
    message?.success('Url copied to clipboard');
  };

  const urlQrCode = () => {
    if (isMobileScreen) {
      if (isLessThan300) {
        return 100;
      }
      return 115;
    }
    if (isTabletScreen) {
      return 110;
    }
    return 120;
  };
  // eslint-disable-next-line no-undef
  const isAdsForMobile = !(window?.innerWidth > 1400);
  return (
    <>
      <Layout className="gx-app-layout">
        <Helmet>
          <title>AppsOnAir - Get UDID</title>
        </Helmet>
        <Header>
          <a href={APPSONAIR_MAIN_LINK} rel="noreferrer">
            <img alt="apps-on-air" src={Logo} className="logo" />
          </a>
          <div className="d-flex align-center">
            <a href={DOWNLOAD_APPSTORE_URL} target="_blank" rel="noreferrer">
              {isTabletScreen ? (
                <MobileAppStoreDownload height={35} width={50} />
              ) : (
                <AppStoreDownload height={40} width={150} />
              )}
            </a>
            {!isIOS && (
              <a href={DOWNLOAD_PLAYSTORE_URL} target="_blank" rel="noreferrer">
                {isTabletScreen ? (
                  <MobilePlayStoreDownload height={35} width={50} />
                ) : (
                  <PlayStoreDownload height={40} width={150} />
                )}
              </a>
            )}
          </div>
        </Header>
        <Content className="gx-layout-content">
          {isAdsForMobile && (
            <div className="google-ad-banner">
              <AdsComponent dataAdSlot="5599605877" width={500} height={90} />
            </div>
          )}
          <div className="gx-main-content-wrapper public">
            <div className="apps-content public-page ios">
              <div className="main-content ios-udid">
                {isIOS ? (
                  <>
                    {udid && product && version ? (
                      <div className="udid-success">
                        <div className="udid-image">
                          <UdidSuccess />
                        </div>
                        <Card
                          title="Device UDID Details"
                          className="m-auto udid-card"
                        >
                          <p className="d-flex align-center justify-center">
                            <b>UDID</b>:{' '}
                            <span className="word-break-word">{udid}</span>
                            <img
                              src={documentCopy}
                              alt="copy"
                              onClick={copyUdid}
                              className="ml-8 pointer"
                              width={20}
                            />
                          </p>
                          <p>
                            <b>Product</b>:{' '}
                            <span className="word-break-word">{product}</span>
                          </p>
                          <p>
                            <b>Version</b>:{' '}
                            <span className="word-break-word">{version}</span>
                          </p>
                        </Card>
                      </div>
                    ) : (
                      <div className="udid-start">
                        <a
                          ref={linkRef}
                          href="App-prefs://prefs:root=General&path=ManagedConfigurationList"
                          className="d-none"
                        >
                          Click me!
                        </a>
                        <div className="udid-image">
                          <UdidStart />
                        </div>
                        <h1 className="mb-20">Get your Device ID (UDID)</h1>
                        <p>
                          Tap the download button to install the temporary
                          profile and iOS will take care of the rest.
                        </p>
                        <p>
                          After downloading the profile you will need to go to
                        </p>
                        <p>
                          <b>Settings -&gt; Profile Downloaded</b>
                        </p>
                        <a
                          href={`${process?.env?.REACT_APP_SERVER_REST_URL}/v1/udid`}
                          download
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              triggerClickAfterOneSecond();
                            }}
                          >
                            Download
                          </Button>
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="udid-error">
                    <h1>Get your UDID easily with AppsOnAir</h1>
                    <div className="udid-image">
                      {isMobileScreen ? (
                        <UdidErrorMobile className="udid-mobile" />
                      ) : (
                        <UdidError />
                      )}
                      <QRcode size={urlQrCode()} value={currentURL} />
                    </div>
                    <p className="or mt-20">OR</p>
                    <p className="mb-24">
                      You can copy this URL and paste it in your iPhone or iPad
                      Safari Browser!
                    </p>
                    <div className="mb-24">
                      <a href={currentURL}>{currentURL}</a>{' '}
                      <img
                        src={documentCopy}
                        alt="copy"
                        onClick={copyUrl}
                        className="ml-8 pointer"
                        width={20}
                      />
                    </div>
                  </div>
                )}
                {!isAdsForMobile && (
                  <div className="left-google-ad">
                    <AdsComponent dataAdSlot="3115003212" />
                  </div>
                )}
                {!isAdsForMobile && (
                  <div className="right-google-ad">
                    <AdsComponent dataAdSlot="5549594866" />
                  </div>
                )}
                {isAdsForMobile && <AdsComponent dataAdSlot="4947815866" />}
              </div>
            </div>
          </div>
          <CommonFooter />
        </Content>
      </Layout>
    </>
  );
};

export default IosDeviceUdid;
