import { Result } from 'antd';
import React from 'react';

const MaintenancePage = () => (
  <div className="empty-data">
    <Result
      status="500"
      title={<h1>Sorry!</h1>}
      subTitle={
        <div>
          <h2>We are under maintenance currently!</h2>
          <h2>We are preparing to serve you better.</h2>
        </div>
      }
    />
  </div>
);
export default MaintenancePage;
