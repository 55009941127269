import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { MODAL_WIDTH } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { RESOLVE_RELEASE_FEEDBACK } from '../graphql/Mutations';
import '../style/appDetails.less';

const { required } = formValidatorRules;

function FeedbackResolvedModal({
  resolvedFeedbackData,
  resolvedFeedbackModal,
  setResolvedFeedbackModal,
  onFinish
}) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form?.useForm();

  const [resolveFeedback] = useMutation(RESOLVE_RELEASE_FEEDBACK, {
    onCompleted() {
      onFinish();
      setResolvedFeedbackModal(false);
      setSaveLoading(false);
    },
    onError() {
      setSaveLoading(false);
    }
  });

  const handleCancel = () => {
    setResolvedFeedbackModal(false);
  };

  const handleSubmitResolvedFeedback = async (values) => {
    setSaveLoading(true);
    resolveFeedback({
      variables: {
        data: {
          feedbackId: resolvedFeedbackData?.id,
          appDetailsId: resolvedFeedbackData?.releaseId,
          resolveComment: values?.addComment,
          isResolved: true
        }
      }
    });
  };

  return (
    <div>
      <Modal
        width={MODAL_WIDTH}
        className="feedback-modal"
        title="Resolve Feedback"
        centered
        destroyOnClose
        onCancel={handleCancel}
        wrapClassName="add-member-modal"
        visible={resolvedFeedbackModal}
        footer={
          <div key="footer">
            <Button
              htmlType="submit"
              className="font-500 cancel-btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="upload-btn"
              type="primary"
              loading={saveLoading}
              onClick={form?.submit}
            >
              Resolve
            </Button>
          </div>
        }
      >
        <Form
          preserve={false}
          form={form}
          name="feedback-resolved-form"
          className="add-comments"
          layout="vertical"
          onFinish={handleSubmitResolvedFeedback}
        >
          <Form.Item
            name="addComment"
            className="feedback-resolve"
            label="Enter your Response"
            rules={[
              {
                ...required,
                message: 'Enter comment!'
              }
            ]}
          >
            <Input.TextArea
              placeholder="Enter your response for resolving this feedback."
              autoSize={{ minRows: 3, maxRows: 5 }}
              maxLength={200}
              showCount
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default FeedbackResolvedModal;
