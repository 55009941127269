import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CURRENT_EMAIL,
  GA_EVENT,
  GA_LABEL,
  IS_NEW_USER,
  ROUTES
} from '../../common/constants';
import { formValidatorRules, googleAnalyticsEvents } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import './Login.less';
import { LOGIN } from './graphql/Mutations';
import AuthLayout from './layout/Layout';

const { required, email } = formValidatorRules;

const Login = () => {
  const history = useHistory();
  const { dispatch, getToken } = useContext(AppContext);
  const idToken = getToken();
  const [loading, setLoading] = useState(true);
  const [tempEmail, setTempEmail] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const [form] = Form?.useForm();

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res?.loginUser?.status === 'Success!') {
        dispatch({ type: 'SET_EMAIL', data: tempEmail });
        // eslint-disable-next-line no-undef
        localStorage?.setItem(CURRENT_EMAIL, tempEmail);
        if (res?.loginUser?.isNewUser) {
          // eslint-disable-next-line no-undef
          localStorage?.setItem(IS_NEW_USER, res?.loginUser?.isNewUser);
        }
        history?.push(ROUTES?.VERIFY_USER);
      }
    },
    onError: () => {
      setTempEmail(null);
    }
  });

  const onFinish = async (values) => {
    const { email: userEmail } = values;
    // converted email into lowercase
    const lowerCaseEmail = userEmail?.toLowerCase();
    googleAnalyticsEvents(GA_EVENT?.SIGN_IN, {
      label: GA_LABEL?.SIGN_IN,
      user_email: lowerCaseEmail
    });
    setTempEmail(lowerCaseEmail);
    await loginMutate({
      variables: {
        email: lowerCaseEmail
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const emailValue = window?.location?.search?.split('?email=')?.[1];
    if (emailValue) {
      setInitialValues({ email: emailValue });
      setLoading(false);
      history?.replace(ROUTES?.LOGIN);
    } else {
      if (idToken) {
        history?.replace(ROUTES?.MAIN);
      } else {
        history?.replace(ROUTES?.LOGIN);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) {
      form?.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  if (loading) return <LoaderComponent />;

  return (
    <AuthLayout>
      <div className="gx-login-content">
        <div className="login-text">
          <span className="login-heading-text font-24">Get Started</span>
          <span className="login-description">
            You will receive 6 digit OTP in your inbox for verification
          </span>
        </div>
        <Form
          name="Login"
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required, email]}>
            <Input className="email-input" placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="send-otp-button font-500"
              loading={loginLoading}
            >
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
