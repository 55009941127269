import { useQuery } from '@apollo/client';
import { Avatar, Button, Col, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import defaultIcon from '../../assets/svg/default-app.svg';
import { GET_APP } from './graphql/Queries';
import './updatepreview.less';

const UpdatePreview = ({
  androidCollapse,
  iosCollapse,
  androidChecked,
  iosChecked
}) => {
  const appID = useParams();
  const id = appID?.appId;

  const { data } = useQuery(GET_APP, {
    fetchPolicy: 'network-only',
    variables: {
      appId: id
    },
    onError(error) {
      return error;
    }
  });

  return (
    <>
      <div className="update-preview-mobile" />
      {androidCollapse || iosCollapse ? (
        <div className="mobile-update">
          <div>
            <Avatar
              src={data?.getApplication?.appLogo || defaultIcon}
              size={18}
            />
            <div className="mobile-preview mt-6">
              <span>{data?.getApplication?.appName} needs an update</span>
            </div>
          </div>
          {(androidChecked || iosChecked) && (
            <>
              <div className="mobile-content mt-8">
                To use this app, download the latest version.
              </div>
              <div className="mobile-button">
                <Button type="primary" size="small">
                  Update
                </Button>
              </div>
            </>
          )}
          {!androidChecked && !iosChecked && (
            <>
              <div className="mobile-content mt-8">
                An Update to {data?.getApplication?.appName} is available. Would
                you like to update ?
              </div>
              <div className="mobile-button">
                <Row gutter={8} className="button-preview">
                  <Col span={12}>No, thanks</Col>
                  <Col span={12}>
                    <Button type="primary" size="small">
                      Update
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="preview">Preview will appear here.</div>
      )}
    </>
  );
};

export default UpdatePreview;
