import { useMutation } from '@apollo/client';
import { Button, Checkbox, Form, message, Progress, Row, Upload } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import androidIcon from '../assets/images/android-icon.png';
import iosIcon from '../assets/images/ios-icon.png';
import {
  ALL_APK_UPLOAD,
  ALL_IPA_UPLOAD,
  MAX_RELEASE_NOTE_CHARACTER,
  MAX_UPLOAD_SIZE,
  ROUTES
} from '../common/constants';
import {
  GET_APP_SIGNED_URL,
  RELEASE_UPDATE_JOB_STATUS
} from '../modules/onboard/graphql/mutation';
import HTMLEditor from './HTMLEditor';
import RouterPrompt from './RouterPrompt';

const { Dragger } = Upload;
const isFileSizeValidate = (inputSizeInBytes, maxLimit) => {
  return Boolean(Math.floor(inputSizeInBytes / (1024 * 1024)) <= maxLimit);
};
const CommonUpload = ({ id }) => {
  const [form] = Form?.useForm();
  const history = useHistory();

  const [state, setState] = useState({
    selectedFile: {},
    uploading: false
  });

  const [apkCurrentFile, setApkCurrentFile] = useState(null);
  const [ipaCurrentFile, setIpaCurrentFile] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [defaultShareLink, setDefaultShareLink] = useState(true);
  const [apkFileList, setApkFileList] = useState([]);
  const [ipaFileList, setIpaFileList] = useState([]);
  const [apkPercentCount, setApkPercentCount] = useState(0);
  const [ipaPercentCount, setIpaPercentCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    state: { teamId },
    dispatch
  } = useContext(AppContext);

  const [generateAppSignedUrl] = useMutation(GET_APP_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
    }
  });

  const uploadApp = async ({ signedURL, currentFileData, config }) => {
    const uploadedApp = await axios?.put(signedURL, currentFileData, config);
    return uploadedApp;
  };

  const [getJobStatus] = useMutation(RELEASE_UPDATE_JOB_STATUS, {
    onCompleted(data) {
      setState({ ...state, uploading: false });
      if (data?.releaseUpdateJobStatus) {
        setApkPercentCount(100);
        setIpaPercentCount(100);
      }
    },
    onError() {
      setState({ ...state, uploading: false });
    }
  });

  const checkValue = (e) => {
    setCheckboxValue(e?.target?.checked);
  };

  const checkDefaultLink = (e) => {
    setDefaultShareLink(e?.target?.checked);
  };

  const onChangeApkUpload = (info) => {
    if (isFileSizeValidate(info?.file?.size, MAX_UPLOAD_SIZE)) {
      const {
        file: { name = '', url },
        fileList
      } = info;

      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      if (ALL_APK_UPLOAD?.includes(ext) && !url) {
        setApkFileList([...info?.fileList]);
        setApkCurrentFile(info?.file);
      } else {
        setApkCurrentFile(null);
        message?.destroy();
        message?.error(`${info?.file?.name} is not APK file.`);
      }
      if (fileList?.length === 0) {
        setApkCurrentFile(null);
      }
    } else {
      message?.error(
        `Chosen file exceed the maximum size of ${MAX_UPLOAD_SIZE} MB`
      );
    }
  };

  const onChangeIpaUpload = (info) => {
    if (isFileSizeValidate(info?.file?.size, MAX_UPLOAD_SIZE)) {
      const {
        file: { name = '', url },
        fileList
      } = info;

      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      if (ALL_IPA_UPLOAD?.includes(ext) && !url) {
        setIpaFileList([...info?.fileList]);
        setIpaCurrentFile(info?.file);
      } else {
        setIpaCurrentFile(null);
        message?.destroy();
        message?.error(`${info?.file?.name} is not IPA file.`);
      }
      if (fileList?.length === 0) {
        setIpaCurrentFile(null);
      }
    } else {
      message?.error(
        `Chosen file exceed the maximum size of ${MAX_UPLOAD_SIZE} MB`
      );
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let apkAppSignedUrlResponse;
      if (apkCurrentFile) {
        apkAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: apkCurrentFile?.name,
              filetype:
                apkCurrentFile?.type ||
                'application/vnd.android.package-archive',
              appId: id,
              fileSize: apkCurrentFile?.size
            }
          }
        });

        if (apkAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type':
                apkCurrentFile?.type ||
                'application/vnd.android.package-archive',
              'Content-Disposition': `attachment; filename=${apkCurrentFile?.name}`,
              'x-amz-acl': 'public-read'
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total
              );
              setApkPercentCount(percentCompleted);
            }
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: apkCurrentFile,
            config
          });

          if (uploadedAppResponse?.status === 200) {
            await getJobStatus({
              variables: {
                data: {
                  jobId:
                    apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId,
                  appId: id,
                  ...(!checkboxValue && {
                    androidNote: values?.androidNote
                  }),
                  isSpecificNote: !checkboxValue,
                  ...(checkboxValue && {
                    oneNote: values?.oneReleaseNote
                  }),
                  isDefaultShareableLink: defaultShareLink
                }
              }
            });
          }
        }
      }
      let ipaAppSignedUrlResponse;
      if (ipaCurrentFile) {
        let ipaJobId = null;
        ipaAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: ipaCurrentFile?.name,
              filetype: 'application/octet-stream',
              appId: id,
              fileSize: ipaCurrentFile?.size
            }
          }
        });

        if (ipaAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': `attachment; filename=${ipaCurrentFile?.name}`,
              'x-amz-acl': 'public-read'
            },

            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total
              );
              setIpaPercentCount(percentCompleted);
            }
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: ipaCurrentFile,
            config
          });
          if (uploadedAppResponse?.status === 200) {
            ipaJobId =
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId;

            await getJobStatus({
              variables: {
                data: {
                  jobId: ipaJobId,
                  appId: id,
                  ...(!checkboxValue && {
                    iosNote: values?.iosNote
                  }),
                  isSpecificNote: !checkboxValue,
                  ...(checkboxValue && {
                    oneNote: values?.oneReleaseNote
                  }),
                  isDefaultShareableLink: defaultShareLink
                }
              }
            });
          }
        }
      }
      setLoading(false);
      dispatch({
        type: 'SET_TEAM',
        data: teamId
      });
      if (apkAppSignedUrlResponse?.data || ipaAppSignedUrlResponse?.data) {
        if (defaultShareLink) {
          dispatch({
            type: 'SET_APP_RELEASE_DEFAULT_LINK',
            data: true
          });
        }
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}`
        );
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Row justify="center" className="onboard-container mb-30">
      <RouterPrompt when={loading} />
      <Form form={form} onFinish={handleSubmit}>
        <fieldset className="fieldset" disabled={loading}>
          <Row className="d-flex justify-center align-center">
            <Form.Item>
              <div className="dragger-parent h-158 mr-15">
                <Dragger
                  accept=".apk"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={onChangeApkUpload}
                  fileList={apkFileList}
                  maxCount={1}
                  className="dragger-inner p-10 h-158"
                  showUploadList={{ showRemoveIcon: !loading }}
                >
                  <div className="mb-18">
                    <img src={androidIcon} alt="android-icon" />
                  </div>

                  {apkPercentCount > 0 && (
                    <div className="android-content">
                      {apkPercentCount === 100 ? (
                        <center />
                      ) : (
                        <Progress
                          type="line"
                          size="small"
                          percent={apkPercentCount}
                          className="apk-progress-bar"
                        />
                      )}
                    </div>
                  )}
                  {!apkCurrentFile && (
                    <div>
                      <span className="content-text">
                        Click or Drag & Drop files here (.apk)
                      </span>
                    </div>
                  )}
                </Dragger>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="dragger-parent h-158">
                <Dragger
                  accept=".ipa"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={onChangeIpaUpload}
                  fileList={ipaFileList}
                  maxCount={1}
                  className="dragger-inner h-158 p-10"
                  showUploadList={{ showRemoveIcon: !loading }}
                >
                  <div className="mb-20">
                    <img src={iosIcon} alt="ios-icon" />
                  </div>

                  {ipaPercentCount > 0 && (
                    <div className="android-content">
                      {ipaPercentCount === 100 ? (
                        <center />
                      ) : (
                        <Progress
                          type="line"
                          size="small"
                          percent={ipaPercentCount}
                        />
                      )}
                    </div>
                  )}
                  {!ipaCurrentFile && (
                    <div className="content-text">
                      <span>Click or Drag & Drop files here (.ipa)</span>
                    </div>
                  )}
                </Dragger>
              </div>
            </Form.Item>
          </Row>
          <Row className="justify-between">
            <Form.Item className="checkbox">
              <Checkbox onChange={checkValue} name="isSpecificNote">
                One Release Note
              </Checkbox>
            </Form.Item>
            <Form.Item className="checkbox">
              <Checkbox
                onChange={checkDefaultLink}
                name="isDefaultShareableLink"
                checked={defaultShareLink}
              >
                Default Share Link
              </Checkbox>
            </Form.Item>
          </Row>
          <Row className="d-flex justify-between align-center">
            {checkboxValue === false ? (
              <>
                <Form.Item
                  name="androidNote"
                  className="b-8 mr-15 width-percent-48"
                  rules={[
                    {
                      max: MAX_RELEASE_NOTE_CHARACTER,
                      message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                    }
                  ]}
                >
                  <HTMLEditor className="html-editor" />
                </Form.Item>

                <Form.Item
                  name="iosNote"
                  className="b-8 width-percent-48"
                  rules={[
                    {
                      max: MAX_RELEASE_NOTE_CHARACTER,
                      message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                    }
                  ]}
                >
                  <HTMLEditor className="html-editor" />
                </Form.Item>
              </>
            ) : (
              <div className="full-width b-8">
                <Form.Item
                  name="oneReleaseNote"
                  rules={[
                    {
                      max: MAX_RELEASE_NOTE_CHARACTER,
                      message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                    }
                  ]}
                >
                  <HTMLEditor className="html-editor" />
                </Form.Item>
              </div>
            )}
          </Row>

          <div className="step-action mt-20 d-flex justify-center">
            <Form.Item shouldUpdate>
              <Button
                htmlType="submit"
                className="upload-btn"
                type="primary"
                disabled={isEmpty(apkCurrentFile || ipaCurrentFile)}
                loading={loading}
              >
                Upload
              </Button>
            </Form.Item>
          </div>
        </fieldset>
      </Form>
    </Row>
  );
};

export default CommonUpload;
