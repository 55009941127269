import React, { useState } from 'react';
import '../style/colorPicker.less';

function ColorPickerWithTextInput({ name, value, onChange }) {
  const [color, setColor] = useState(value);

  const handleChange = (e) => {
    const val = e?.target?.value;
    setColor(val);
    onChange(val);
  };

  return (
    <div className="picker-container">
      <input
        type="color"
        className="color-picker"
        name={name}
        value={color}
        onChange={handleChange}
      />
      <input
        type="text"
        className="color-text"
        name={name}
        value={color}
        onChange={handleChange}
      />
    </div>
  );
}

export default ColorPickerWithTextInput;
