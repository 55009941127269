import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import defaultIcon from '../../assets/svg/default-app.svg';
import { formValidatorRules } from '../../common/utils';
import AuthLayout from '../auth/layout/Layout';
import Detail from './Detail';
import { GET_APP_DETAIL } from './graphql/Queries';

const { required } = formValidatorRules;

const VerifyLinkPassword = ({ setShowHeader, appDetails = null }) => {
  const appID = useParams();
  const { subLinkId, uniqueId } = appID;
  const [password, setPassword] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  const getAppInput = {
    uniqueId: uniqueId,
    subLinkId: subLinkId,
    password
  };
  const [getAppDetailData, { loading }] = useLazyQuery(GET_APP_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.getApp?.appData?.id) {
        setShowHeader(false);
        setShowDetail(true);
      }
    }
  });

  const handleSubmit = (values) => {
    setPassword(values?.password);
    getAppDetailData({
      variables: {
        getAppInput: {
          ...getAppInput,
          password: values?.password
        }
      }
    });
  };

  return (
    <>
      {!showDetail && (
        <AuthLayout>
          <div className="gx-login-content">
            <div>
              <Avatar
                src={appDetails?.appLogo || defaultIcon}
                alt="application-img"
                className="avatar-width"
                size={28}
              />
              <span
                className="header-app-name font-16"
                title={appDetails?.appName}
              >
                {appDetails?.appName}
              </span>
            </div>
            <div className="login-text">
              <span className="login-heading-text font-24">Enter Password</span>
              <span className="line-24 mb-24">
                This app is password protected. Please enter the password to
                install the app.
              </span>
            </div>
            <Form
              name="Password"
              className="gx-login-form gx-form-row0"
              onFinish={handleSubmit}
            >
              <Form.Item name="password" rules={[required]}>
                <Input
                  placeholder="Password"
                  type="password"
                  className="password-input"
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="send-otp-button"
                loading={loading}
              >
                Verify
              </Button>
            </Form>
          </div>
        </AuthLayout>
      )}
      {showDetail && <Detail password={password} />}
    </>
  );
};

export default VerifyLinkPassword;
