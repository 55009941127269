import React, { useEffect } from 'react';

const AdsComponent = (props) => {
  const { dataAdSlot, height, width } = props;

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      (window.adsbygoogle = window?.adsbygoogle || [])?.push({});
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          ...(width && {
            width: `${width}px`
          }),
          ...(height && {
            height: `${height}px`
          })
        }}
        data-ad-client={process?.env?.REACT_APP_GOOGLE_ADS_CLIENT_ID}
        data-ad-slot={dataAdSlot}
        data-ad-test={process?.env?.REACT_APP_GOOGLE_ADS_TEST_MODE}
        {...(!(width && height) && {
          'data-ad-format': 'auto',
          'data-full-width-responsive': 'true'
        })}
      />
    </>
  );
};

export default AdsComponent;
