import React, { useContext } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { AppContext } from './AppContext';
import { REDIRECT_WAITING, ROUTES } from './common/constants';

function PublicRoute({ component: Component, ...rest }) {
  const { getToken } = useContext(AppContext);
  const idToken = getToken();
  // eslint-disable-next-line no-undef
  const data = localStorage?.getItem(REDIRECT_WAITING);

  return (
    <Route
      {...rest}
      render={(props) =>
        idToken && !data ? (
          <Redirect to={ROUTES?.MAIN} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default withRouter(PublicRoute);
