import { Row, Steps } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import '../modules/onboard/style/onboardingStepper.less';

const { Step } = Steps;

const steps = [
  {
    key: 'WORKSPACE',
    title: 'Name Your Workspace',
    content: 'First-content'
  },
  {
    key: 'CREATE_APP',
    title: 'Create App',
    content: 'Second-content'
  },
  {
    key: 'INVITE_MEMBERS',
    title: 'Invite Members',
    content: 'Last-content'
  }
];

const OnboardingStepper = ({ history }) => {
  const current = () => {
    let currentStep;
    if (history?.location?.pathname?.includes(ROUTES?.WORKSPACE)) {
      currentStep = 0;
    } else if (history?.location?.pathname?.includes(ROUTES?.APP_CREATE)) {
      currentStep = 1;
    } else {
      currentStep = 2;
    }
    return currentStep;
  };

  return (
    <div className="steps-wrapper">
      <Row justify="center" className="mt-32 onboard-container mb-40">
        <div className="stepper-content">
          <Steps
            labelPlacement="vertical"
            size="small"
            current={current()}
            className="step-bar"
          >
            {steps?.map((item) => (
              <Step key={item?.key} title={item?.title} />
            ))}
          </Steps>
        </div>
      </Row>
    </div>
  );
};

export default withRouter(OnboardingStepper);
