import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Avatar, Button, Modal, Tag } from 'antd';
import React from 'react';
import defaultIcon from '../../../assets/svg/default-app.svg';
import { MODAL_WIDTH } from '../../../common/constants';
import '../style/appDetails.less';

const DeleteRelease = ({
  showDeleteReleaseModal,
  handleVisible,
  selectedPlatform,
  data,
  version,
  build,
  mutation
}) => {
  const handleOk = () => {
    mutation();
    handleVisible(false);
  };
  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="delete-release"
        title="Delete Release"
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        visible={showDeleteReleaseModal}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="delete-btn" onClick={handleOk} type="danger">
              Delete
            </Button>
          </div>
        ]}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-header-left d-flex">
              <Avatar
                src={data?.getApplication?.appLogo || defaultIcon}
                alt="release-img"
                className="mr-15 mb-20 release-avatar"
              />
              <h2>{data?.getApplication?.appName}</h2>
            </div>
            <div className="modal-header-right">
              {selectedPlatform === 'IOS' ? (
                <AppleFilled size={18} className="ios-color" />
              ) : (
                <AndroidFilled className="android-color" />
              )}
              <Tag>Version: {version}</Tag>
              <Tag>Build: {build}</Tag>
            </div>
          </div>
          <div>Are you sure you want to delete this release?</div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteRelease;
