import { Button, Row } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import noRelease from '../../assets/images/no-release.png';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './style/noRelease.less';

const NoReleases = () => {
  const history = useHistory();
  const appID = useParams();
  const id = appID?.appId;

  const newRelease = () => {
    const path = `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}${ROUTES?.CREATE_RELEASE}`;
    history?.push(path);
  };

  return (
    <>
      <div className="no-release d-flex justify-center align-center">
        <div className="no-release-img">
          <img src={noRelease} alt={noRelease} width={394} />
          <div className="no-release-content d-flex justify-center flex-vertical align-center">
            <Row className="row-app">No builds in this app</Row>
            <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE}>
              <Row className="row-create font-16">
                Create releases in this app.
              </Row>
            </AccessControl>
            <div className="button-release">
              <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE}>
                <Button type="primary" onClick={newRelease}>
                  Create Release
                </Button>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoReleases;
