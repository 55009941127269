import { Avatar, Tooltip } from 'antd';
import React from 'react';
import defaultIcon from '../../../assets/svg/default-app.svg';

const ApplicationList = ({ data }) => {
  return (
    <Avatar.Group
      maxCount={2}
      maxStyle={{
        color: '#585E75',
        backgroundColor: '#F2F4F7',
        height: '24px',
        width: '24px',
        lineHeight: '24px',
        fontSize: '12px'
      }}
    >
      {data?.map((value) => {
        return (
          <Tooltip title={value?.appName} key={value?.id}>
            <Avatar
              size={24}
              key={value?.id}
              src={value?.appLogo || defaultIcon}
              alt="logo"
            />
          </Tooltip>
        );
      })}
    </Avatar.Group>
  );
};

export default ApplicationList;
