import { Alert, Avatar, Button, Card, Descriptions, Tag } from 'antd';
import { isIOS, isSafari } from 'mobile-device-detect';
import QRcode from 'qrcode.react';
import React from 'react';
import defaultIcon from '../../assets/svg/default-app.svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import { covertAppSizeIntoKbOrMB } from '../../common/utils';

const MobileDetailPage = ({
  data,
  link,
  copyUniqueId,
  appSize,
  subLinkId,
  appStorageUrl,
  downloadBtn
}) => {
  const deviceType = data?.getApp?.appData?.appMetadata?.provisionedDevices
    ? 'ios'
    : 'android';

  const appSizeInUnit = covertAppSizeIntoKbOrMB(appSize);

  return (
    <div className="app-detail-container">
      <div className="item">
        <Card
          bordered
          title="App Overview"
          extra={
            <>
              <Tag>
                <span className="extra-text font-12">
                  Version: {data?.getApp?.appData?.appVersion}
                </span>
              </Tag>
              <Tag>
                <span className="extra-text font-12">
                  Build: {data?.getApp?.appData?.appBuild}
                </span>
              </Tag>
            </>
          }
          actions={[
            <div key="download">
              <a
                href={appStorageUrl}
                onClick={downloadBtn}
                className="d-flex justify-start"
              >
                <Button
                  key="download"
                  type="primary"
                  className="mobile-download"
                >
                  Download Application
                </Button>
              </a>
              {isIOS && !isSafari && (
                <Alert
                  className="mt-8"
                  message="Open this page in Safari on your IOS device to be able to
              install the app."
                  type="info"
                  showIcon
                />
              )}
            </div>
          ]}
        >
          <div className="d-flex align-center">
            <Avatar
              src={data?.getApp?.appData?.appLogo || defaultIcon}
              size={48}
              className="mr-12"
            />
            <span className="app-name-text font-20">
              {data?.getApp?.appData?.appName}
            </span>
          </div>
        </Card>
      </div>

      {data?.getApp?.appData?.releaseNote !== null && (
        <div className="item">
          <div className="release-item-detail">
            <Card bordered title="Release Note">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data?.getApp?.appData?.releaseNote
                }}
              />
            </Card>
          </div>
        </div>
      )}

      <div className="item">
        <Card bordered title="Build Details">
          <div className="build-details-content">
            <Descriptions layout="vertical" colon={false} column={4}>
              <Descriptions.Item label="Bundle Identifier" span={4}>
                {data?.getApp?.appData?.appPackageName}
              </Descriptions.Item>
              <Descriptions.Item label="Minimum OS Version" span={2}>
                {deviceType === 'ios'
                  ? data?.getApp?.appData?.appMetadata?.minimumOSVersion
                  : data?.getApp?.appData?.appMetadata?.minSdkVersion}
              </Descriptions.Item>
              <Descriptions.Item label="Target OS Version" span={2}>
                {data?.getApp?.appData?.appMetadata?.targetSdkVersion || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Device Capabilities" span={2}>
                {data?.getApp?.appData?.appMetadata?.uiRequiredDeviceCapabilities?.join(
                  ', '
                ) || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Size">
                {appSizeInUnit}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      </div>

      <div className="item">
        <div className="app-detail-qr">
          <span className="public-content">Public URL</span>
          <div className="d-flex justify-center">
            <div className="qr-content">
              <QRcode size={245} value={link} />
              <div className="d-flex flex-horizontal justify-center qr-content-row line-49">
                <img
                  src={documentCopy}
                  alt="copy"
                  onClick={copyUniqueId}
                  className="mr-8"
                  width={20}
                />
                <span className="copy-id">{subLinkId}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="permission-card">
          <Card
            bordered
            title={
              <div className="d-flex align-center">
                <span className="mr-8">
                  {deviceType === 'ios' ? 'Provisional Devices' : 'Permissions'}
                </span>
                <div className="count">
                  {deviceType === 'ios'
                    ? data?.getApp?.appData?.appMetadata?.provisionedDevices
                        ?.length
                    : data?.getApp?.appData?.appMetadata?.permissions?.length}
                </div>
              </div>
            }
          >
            <div className="permission-content">
              {deviceType === 'ios'
                ? data?.getApp?.appData?.appMetadata?.provisionedDevices?.map(
                    (detail) => {
                      return <span key={detail}>{detail}</span>;
                    }
                  )
                : data?.getApp?.appData?.appMetadata?.permissions?.map(
                    (detail) => {
                      return <span key={detail}>{detail}</span>;
                    }
                  )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MobileDetailPage;
