import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Card, Col, Empty, Input, Row, Spin, Tag } from 'antd';
import { debounce, trim, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import addMember from '../../../assets/images/add-member.png';
import { ACTIONS, GA_EVENT, GA_LABEL, LIMIT } from '../../../common/constants';
import { avatarColor, googleAnalyticsEvents } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_MEMBER_APPS_LIST } from '../graphql/Queries';
import ApplicationList from './ApplicationList';
import MembersModal from './MembersModal';

let debounceScroll;
let searchDebounce = null;

const MembersList = ({
  teamId,
  updateTeamsComp = false,
  setUpdateTeamsComp
}) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [memberModal, setMemberModal] = useState(false);
  const [count, setCount] = useState();
  const [record, setRecord] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchLoader, setSearchLoader] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [searchData, setSearchData] = useState();

  const [memberLists] = useLazyQuery(GET_MEMBER_APPS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setCount(res?.getMemberAppsList?.count);
      if (scrollFlag) {
        const tempMemberData = [
          ...(memberData || []),
          ...(res?.getMemberAppsList?.appsDetails || [])
        ];
        setMemberData(uniqBy([...tempMemberData], 'id'));
        setScrollFlag(false);
      } else {
        setMemberData([
          ...(uniqBy(res?.getMemberAppsList?.appsDetails, 'id') || [])
        ]);
      }
      setIsEnd(res?.getMemberAppsList?.appsDetails?.length < LIMIT);
      setLoader(false);
      setSearchLoader(false);
      setUpdateTeamsComp(false);
    },
    onError() {
      setLoader(false);
      setSearchLoader(false);
    }
  });

  useEffect(() => {
    memberLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (updateTeamsComp) {
      setLoader(true);
      setIsEnd(false);
      memberLists({
        variables: {
          data: {
            teamId,
            skip: 0,
            limit: LIMIT
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTeamsComp]);

  const handleAppSearch = (value) => {
    setSearchLoader(true);
    memberLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT,
          search: value
        }
      }
    });
  };

  const handleChange = ({ target: { value } }) => {
    setSearchData(value);
    setIsEnd(false);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;
    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 20;
      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: memberData?.length,
              limit: LIMIT,
              search: searchData
            }
          }
        });
      }
    }, 500);
    debounceScroll();
  };

  const editUser = (editRecord) => {
    setMemberModal(true);
    setRecord(editRecord);
    setIsEdit(true);
  };

  return (
    <div className="members-list-wrap responsive-cards">
      <Card
        title="Members"
        className="card-body"
        extra={<div className="count">{count ?? 0}</div>}
      >
        {loader ? (
          <LoaderComponent setHeight="500" />
        ) : (
          <>
            <div className="search-member d-flex justify-between align-center">
              <Input
                prefix={<SearchOutlined className="font-12 search-color" />}
                placeholder="Search"
                onChange={handleChange}
                value={searchData}
                allowClear
              />
              <AccessControl allowedPermissions={ACTIONS?.CREATE_TEAM}>
                <div className="add-member">
                  <Avatar
                    size={40}
                    shape="circle"
                    className="avatar-add-icon"
                    onClick={() => {
                      googleAnalyticsEvents(GA_EVENT?.ADD_NEW_MEMBER, {
                        label: GA_LABEL?.ADD_NEW_MEMBER,
                        member_id: currentUser?.id
                      });
                      setMemberModal(true);
                    }}
                  >
                    <img
                      src={addMember}
                      alt="img"
                      className="avatar-image-icon"
                    />
                  </Avatar>
                </div>
              </AccessControl>
            </div>
            <div className="member-list-title d-flex justify-between align-center">
              <span>Members</span>
              <div className="add-member">
                <span>Shared Apps</span>
              </div>
            </div>
            {memberData?.length === 0 ? (
              <div className="no-data-found">
                <Empty />
              </div>
            ) : (
              <>
                {searchLoader ? (
                  <div className="ant-loader">
                    <LoaderComponent setHeight="500" spinning={searchLoader} />
                  </div>
                ) : (
                  <div className="member-list" onScroll={handleScroll}>
                    {memberData?.map((details) => {
                      return (
                        <div key={details?.id} className="member-list-row">
                          <Row
                            className="member-lists"
                            onClick={() => editUser(details)}
                          >
                            <Col md={2} lg={2} xl={2} xxl={2}>
                              <Avatar
                                size={38}
                                style={
                                  !details?.profileImage && {
                                    backgroundColor: avatarColor(
                                      details?.firstName ||
                                        details?.invitedUserEmail
                                    )
                                  }
                                }
                                className="no-hover"
                                src={details?.profileImage}
                              >
                                <span className="font-500">
                                  {details?.firstName
                                    ?.charAt(0)
                                    ?.toUpperCase() ||
                                    details?.invitedUserEmail
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                </span>
                              </Avatar>
                            </Col>
                            <Col md={9} lg={9} xl={9} xxl={9}>
                              <div className="member-tag">
                                {details?.userRegisterStatus ===
                                'REGISTERED' ? (
                                  <div className="name-tags line-22">
                                    {trim(
                                      `${details?.firstName || ''} ${
                                        details?.lastName || ''
                                      }`
                                    )}
                                  </div>
                                ) : (
                                  <Tag className="tag-bg">Invited</Tag>
                                )}
                                <span
                                  title={details?.invitedUserEmail}
                                  className="user-email"
                                >
                                  {details?.invitedUserEmail}
                                </span>
                              </div>
                            </Col>
                            <Col md={7} lg={7} xl={7} xxl={7}>
                              <Tag className="member-tag-role">
                                {details?.role}
                              </Tag>
                            </Col>
                            <Col
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="d-flex justify-end"
                            >
                              <ApplicationList
                                data={details?.applicationList}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    {scrollFlag && (
                      <div className="ant-loader">
                        <Spin spinning={scrollFlag} />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {memberModal && (
          <MembersModal
            memberModal={memberModal}
            handleVisible={setMemberModal}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            record={record}
            memberLists={memberLists}
            setListLoader={setLoader}
            setIsListEnd={setIsEnd}
          />
        )}
      </Card>
    </div>
  );
};

export default MembersList;
