import { Col, Layout, Row } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../../assets/images/logo-big.png';
import { ROUTES } from '../../../common/constants';
import CommonFooter from '../../../components/CommonFooter';
import './layout.less';

const allowedFooter = [ROUTES?.LOGIN, ROUTES?.VERIFY_USER];

function AuthLayout({ children }) {
  const location = useLocation();
  return (
    <div className="layout fill-width">
      <Row className="login-row" justify="space-around" align="middle">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="logo-section"
        >
          <img src={logo} alt="appsOnAir" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Layout.Content className="login-border">{children}</Layout.Content>
        </Col>
      </Row>
      {allowedFooter?.includes(location?.pathname) && <CommonFooter />}
    </div>
  );
}

export default AuthLayout;
