/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  CREATE_APP: '/create-app',
  LOGOUT: '/logout',
  LOGIN: '/login',
  REFRESH_TOKEN: '/refresh-token',
  ACCESS_TOKEN: '/access-denied',
  APPS: '/apps',
  DETAILS: '/app-details',
  RELEASES: '/releases',
  RELEASE: '/upload-release',
  APPS_SERVICES: '/apps-services',
  MEMBERS: '/members',
  UPDATE: '/update',
  MAINTENANCE: '/maintenance',
  API_KEY: '/api-key',
  SHARE: '/app-share',
  TEAMS: '/teams',
  INTEGRATIONS: '/integrations',
  APP_DETAIL: '/app-detail',
  VERIFY_PASSWORD: '/verify-password',
  INSTALL: '/install',
  PROFILE_SETTINGS: '/profile-settings',
  WORKSPACE_SETTINGS: '/workspace-settings',
  USERS_MANAGEMENT: '/users',
  CREATE_USERS: '/create-users',
  IOS_UDID: '/get-udid',

  // onboarding steps
  ONBOARD: '/onboard-steps',
  WORKSPACE: '/workspace',
  APP_CREATE: '/app-create',
  INVITE_MEMBERS: '/invite-members',

  // app-release-details
  VERIFY_USER: '/verify-user',
  CREATE_USER: '/create-user',
  APP_SETTINGS: '/app-settings',
  BUILD_SHARE: '/build-share',
  CREATE_RELEASE: '/create-release',
  NEW_RELEASE: '/new-release'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Management'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const TEAM_KEY = 'TEAM_KEY';
export const CURRENT_EMAIL = 'CURRENT_EMAIL';
export const IS_NEW_USER = 'IS_NEW_USER';
export const REDIRECT_WAITING = 'redirectWaiting';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  DEVELOPER: 'DEVELOPER',
  TESTER: 'TESTER'
};

const { ADMIN, OWNER, DEVELOPER } = ROLE_KEYS;

export const ACTIONS = {
  CREATE_APP: 'CREATE_APP',
  UPDATE_APP: 'UPDATE_APP',
  DELETE_APP: 'DELETE_APP',
  CREATE_TEAM: 'CREATE_TEAM',
  CREATE_GROUP: 'CREATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  ADD_MEMBER: 'ADD_MEMBER',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  ADD_APP_MEMBER: 'ADD_APP_MEMBER',
  REMOVE_APP_MEMBER: 'REMOVE_APP_MEMBER',
  APP_RELEASE: 'APP_RELEASE',
  DELETE_RELEASE: 'DELETE_RELEASE',
  DELETE_FEEDBACK: 'DELETE_FEEDBACK',
  APP_SHARE: 'APP_SHARE',
  UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
  UPDATE_WORKSPACE: 'UPDATE_WORKSPACE',
  UPDATE_APP_SERVICE: 'UPDATE_APP_SERVICE',
  FEEDBACK_RESOLVED_ARCHIVED: 'FEEDBACK_RESOLVED_ARCHIVED',
  INTEGRATION_SETTINGS: 'INTEGRATION_SETTINGS'
};

export const PERMISSIONS = {
  CREATE_APP: [ADMIN, OWNER, DEVELOPER],
  UPDATE_APP: [ADMIN, OWNER],
  APP_RELEASE: [ADMIN, OWNER, DEVELOPER],
  APP_SHARE: [ADMIN, OWNER, DEVELOPER],
  DELETE_RELEASE: [ADMIN, OWNER],
  DELETE_APP: [ADMIN, OWNER],
  CREATE_TEAM: [ADMIN, OWNER],
  UPDATE_TEAM_MEMBER: [ADMIN, OWNER],
  DELETE_TEAM: [OWNER],
  CREATE_GROUP: [ADMIN, OWNER],
  EDIT_GROUP: [ADMIN, OWNER],
  DELETE_GROUP: [ADMIN, OWNER],
  ADD_MEMBER: [ADMIN, OWNER],
  REMOVE_MEMBER: [ADMIN, OWNER],
  ADD_APP_MEMBER: [ADMIN, OWNER],
  REMOVE_APP_MEMBER: [ADMIN, OWNER],
  UPDATE_WORKSPACE: [ADMIN, OWNER],
  UPDATE_APP_SERVICE: [ADMIN, OWNER, DEVELOPER],
  FEEDBACK_RESOLVED_ARCHIVED: [ADMIN, OWNER],
  INTEGRATION_SETTINGS: [ADMIN, OWNER]
};

/* OTP */
export const OTP_SIZE = 6;
export const OTP_ATTEMPT = 3;

/* Limit */
export const LIMIT = 10;
export const APP_LIST_LIMIT = 20;

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const ALL_APK_UPLOAD = ['apk'];
export const ALL_IPA_UPLOAD = ['ipa'];
export const ALL_IMAGE_UPLOAD = ['jpeg', 'jpg', 'png'];
export const ALL_IMAGE_VIDEO_UPLOAD = ['jpeg', 'jpg', 'png', 'mp4', 'mov'];

export const AVATAR_COLOR = [
  '#173F5F',
  '#1F639C',
  '#4DA480',
  '#CB543F',
  '#7046A6',
  '#AB567F',
  '#667C89',
  '#49A8EE',
  '#D63964'
];

/* For iOS app */
export const DOWNLOAD_IOS_APP_URL = `itms-services://?action=download-manifest&url=`;
export const DEVELOPER_GUIDELINES_URL = `https://documentation.appsonair.com/`;

export const MAX_RELEASE_NOTE_CHARACTER = 5000;
export const MODAL_WIDTH = 600;

/* Terms and Condition Link */
export const TERMS_AND_CONDITION_LINK =
  'https://appsonair.com/terms-and-condition';

export const APPSONAIR_MAIN_LINK = 'https://appsonair.com/';
/* Download Appsonair links for IOS and ANDROID */
export const DOWNLOAD_APPSTORE_URL =
  'https://apps.apple.com/in/app/appsonair/id6443620901';
export const DOWNLOAD_PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.logicwind.appsonair&hl=en&gl=US';

export const LOGICWIND_URL = 'https://www.logicwind.com';

export const MAX_UPLOAD_SIZE = 1024;

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIP_CODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  WEB_URL_PLAY_STORE: /^https:\/\/play\.google\.com\/.*/,
  WEB_URL_APP_STORE: /^https:\/\/apps\.apple\.com\/.*/,
  WEB_URL_PLAY_STORE_AND_APPSONAIR: /^https:\/\/play\.google\.com\/.*|^https:\/.*\.appsonair\.com\/install\/.*/,
  WEB_URL_APP_STORE_AND_APPSONAIR: /^https:\/\/apps\.apple\.com\/.*|^https:\/.*\.appsonair\.com\/install\/.*/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONAL_NEGATIVE_AMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  INPUT_LENGTH: /^.{6,16}$/
};

export const GA_EVENT = {
  CHANGE_WORKSPACE: 'change_workspace_data_fetch',
  CREATE_NEW_WORKSPACE: 'create_new_workspace_data_fetch',
  USER_LOGOUT: 'user_logout_data_fetch',
  NEW_RELEASE: 'new_release_data_fetch',
  RELEASE_DOWNLOAD: 'release_download_data_fetch',
  RELEASE_VIEW_RELEASE_NOTE: 'release_view_release_note_data_fetch',
  RELEASE_PERMISSION: 'release_permissions_data_fetch',
  RELEASE_VIEW_ALL_SHARE_LINKS: 'release_view_all_share_links_data_fetch',
  RELEASE_DELETE: 'release_delete_data_fetch',
  RELEASE_SHARE: 'release_share_data_fetch',
  RELEASE_FEEDBACK: 'release_feedback_data_fetch',
  CREATE_APP: 'create_app_data_fetch',
  SIGN_IN: 'sign_in_data_fetch',
  USER_PROFILE_EDIT: 'user_profile_edit_data_fetch',
  VERIFY_USER_WITH_OTP: 'verify_user_with_otp_data_fetch',
  SEND_OTP_AGAIN: 'send_otp_again_data_fetch',
  TERMS_AND_CONDITION_LINK: 'terms_and_condition_link_data_fetch',
  CREATE_NEW_GROUP: 'create_new_group_data_fetch',
  ADD_NEW_MEMBER: 'add_new_member_data_fetch',
  SKIP_ONBOARDING_INVITE_MEMBER: 'skip_onboarding_invite_member',
  APP_SEARCH: 'app_search_input',
  SHARE_APPLICATION_DOWNLOAD: 'share_application_download',
  SKIP_ONBOARDING_CREATE_APP: 'skip_onboarding_create_app'
};

export const GA_LABEL = {
  CHANGE_WORKSPACE: 'Change Workspace',
  CREATE_NEW_WORKSPACE: 'Create New Workspace',
  USER_LOGOUT: 'User Logout',
  NEW_RELEASE: 'New Release',
  RELEASE_DOWNLOAD: 'Release Download',
  RELEASE_VIEW_RELEASE_NOTE: 'Release View Release Note',
  RELEASE_PERMISSION: 'Release Permission',
  RELEASE_VIEW_ALL_SHARE_LINKS: 'Release View All Share Links',
  RELEASE_DELETE: 'Release Delete',
  RELEASE_SHARE: 'Release Share',
  RELEASE_FEEDBACK: 'Release Feedback',
  CREATE_APP: 'Create App',
  SIGN_IN: 'Sign In',
  USER_PROFILE_EDIT: 'User Profile Edit',
  VERIFY_USER_WITH_OTP: 'Verify User With OTP',
  SEND_OTP_AGAIN: 'Send OTP Again',
  TERMS_AND_CONDITION_LINK: 'Terms And Condition Link',
  CREATE_NEW_GROUP: 'Create New Group',
  ADD_NEW_MEMBER: 'Add New Member',
  SKIP_ONBOARDING_INVITE_MEMBER: 'Skip Onboarding Invite Member',
  APP_SEARCH: 'App Search Input',
  SHARE_APPLICATION_DOWNLOAD: 'Share Application Download',
  SKIP_ONBOARDING_CREATE_APP: 'Skip Onboarding Create App'
};

export const WORKSPACE_FEATURES_TAB = {
  INTEGRATIONS: 'INTEGRATIONS'
};
