import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_APP = gql`
  mutation createApplication($data: AppDataInput!) {
    createApplication(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;
