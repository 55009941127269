import { Col, message, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import documentCopy from '../../assets/svg/document-copy.svg';
import { DEVELOPER_GUIDELINES_URL } from '../../common/constants';
import { copyToClipboard } from '../../common/utils';
import AppNameHeader from '../../components/AppNameHeader';
import './apikey.less';
import AppServices from './AppServices';

const ApiKey = () => {
  const appID = useParams();
  const id = appID?.appId;

  const copy = () => {
    copyToClipboard(id);
    message?.success('API key copied to clipboard');
  };

  return (
    <>
      <AppNameHeader />
      <AppServices>
        <Row>
          <Col span={18}>
            <Row>
              <div className="s-content font-12 mb-16">
                To use this feature, you have to integrate our app SDK.
                View&nbsp;
                <u>
                  <a
                    href={DEVELOPER_GUIDELINES_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="link font-500"
                  >
                    Developer guidelines
                  </a>
                </u>{' '}
                to know more.
              </div>
            </Row>
            <div className="api-key-content">
              <Row className="row-content font-16">
                <span>App ID</span>
              </Row>
              <Row className="api-key-copy">
                <Col className="col-content">
                  <div>{id}</div>
                </Col>
                <Col className="col-content">
                  <div>
                    <img
                      src={documentCopy}
                      alt="icon"
                      onClick={() => copy(id)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </AppServices>
    </>
  );
};

export default ApiKey;
