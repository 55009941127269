import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Progress,
  Row,
  Upload
} from 'antd';
import axios from 'axios';
import React, { isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import androidIcon from '../assets/images/android-icon.png';
import iosIcon from '../assets/images/ios-icon.png';
import nextButtonArrow from '../assets/svg/next-button-arrow.svg';
import rightArrow from '../assets/svg/right-arrow.svg';
import {
  ALL_APK_UPLOAD,
  ALL_IPA_UPLOAD,
  GA_EVENT,
  GA_LABEL,
  MAX_RELEASE_NOTE_CHARACTER,
  MAX_UPLOAD_SIZE,
  ROUTES
} from '../common/constants';
import { formValidatorRules, googleAnalyticsEvents } from '../common/utils';
import {
  CREATE_APPLICATION,
  GET_APP_SIGNED_URL,
  RELEASE_UPDATE_JOB_STATUS
} from '../modules/onboard/graphql/mutation';
import HTMLEditor from './HTMLEditor';
import RouterPrompt from './RouterPrompt';

const { Dragger } = Upload;
const isFileSizeValidate = (inputSizeInBytes, maxLimit) => {
  return Boolean(Math.floor(inputSizeInBytes / (1024 * 1024)) <= maxLimit);
};
const CommonDragger = () => {
  const [form] = Form?.useForm();

  const [state, setState] = useState({
    selectedFile: {},
    uploading: false
  });
  const [apkCurrentFile, setApkCurrentFile] = useState(null);
  const [ipaCurrentFile, setIpaCurrentFile] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [defaultShareLink, setDefaultShareLink] = useState(true);
  const [apkFileList, setApkFileList] = useState([]);
  const [ipaFileList, setIpaFileList] = useState([]);
  const [apkPercentCount, setApkPercentCount] = useState(0);
  const [ipaPercentCount, setIpaPercentCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { selectedFile } = state;

  const {
    state: { teamId },
    dispatch
  } = useContext(AppContext);
  const history = useHistory();

  const [generateAppSignedUrl] = useMutation(GET_APP_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
    }
  });
  const [createApplication] = useMutation(CREATE_APPLICATION, {
    onError() {}
  });

  const InviteMembers = () => {
    googleAnalyticsEvents(GA_EVENT?.SKIP_ONBOARDING_CREATE_APP, {
      label: GA_LABEL?.SKIP_ONBOARDING_CREATE_APP
    });

    const path = `${ROUTES?.ONBOARD}${ROUTES?.INVITE_MEMBERS}`;
    history?.push(path);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData?.append('file', selectedFile);

    setState({
      ...state,
      uploading: true
    });
  };

  const uploadApp = async ({ signedURL, currentFileData, config }) => {
    const uploadedApp = await axios?.put(signedURL, currentFileData, config);
    return uploadedApp;
  };

  const [getJobStatus] = useMutation(RELEASE_UPDATE_JOB_STATUS, {
    onCompleted(data) {
      setState({ ...state, uploading: false });
      if (data?.releaseUpdateJobStatus) {
        setApkPercentCount(100);
        setIpaPercentCount(100);
      }
    },
    onError() {
      setState({ ...state, uploading: false });
    }
  });

  const checkValue = (e) => {
    setCheckboxValue(e?.target?.checked);
  };

  const checkDefaultLink = (e) => {
    setDefaultShareLink(e?.target?.checked);
  };

  const onChangeApkUpload = (info) => {
    if (isFileSizeValidate(info?.file?.size, MAX_UPLOAD_SIZE)) {
      const {
        file: { name = '', url },
        fileList
      } = info;

      const ext = name?.substring(name?.lastIndexOf('.') + 1);

      if (ALL_APK_UPLOAD?.includes(ext) && !url) {
        setApkFileList([...info?.fileList]);
        setApkCurrentFile(info?.file);
      } else {
        setApkCurrentFile(null);
        message?.destroy();
        message?.error(`${info?.file?.name} is not APK file.`);
      }
      if (fileList?.length === 0) {
        setApkCurrentFile(null);
      }
    } else {
      message?.error(
        `Chosen file exceed the maximum size of ${MAX_UPLOAD_SIZE} MB`
      );
    }
  };

  const onChangeIpaUpload = (info) => {
    if (isFileSizeValidate(info?.file?.size, MAX_UPLOAD_SIZE)) {
      const {
        file: { name = '', url },
        fileList
      } = info;

      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      if (ALL_IPA_UPLOAD?.includes(ext) && !url) {
        setIpaFileList([...info?.fileList]);
        setIpaCurrentFile(info?.file);
      } else {
        setIpaCurrentFile(null);
        message?.destroy();
        message?.error(`${info?.file?.name} is not IPA file.`);
      }
      if (fileList?.length === 0) {
        setIpaCurrentFile(null);
      }
    } else {
      message?.error(
        `Chosen file exceed the maximum size of ${MAX_UPLOAD_SIZE} MB`
      );
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const createApplicationResponse = await createApplication({
        variables: {
          data: {
            teamId,
            name: values?.appName
          }
        }
      });
      let apkAppSignedUrlResponse;
      if (apkCurrentFile) {
        apkAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: apkCurrentFile?.name,
              filetype:
                apkCurrentFile?.type ||
                'application/vnd.android.package-archive',
              appId:
                createApplicationResponse?.data?.createApplication?.data?.id,
              fileSize: apkCurrentFile?.size
            }
          }
        });

        if (apkAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type':
                apkCurrentFile?.typ ||
                'application/vnd.android.package-archive',
              'Content-Disposition': `attachment; filename=${apkCurrentFile?.name}`,
              'x-amz-acl': 'public-read'
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total
              );
              setApkPercentCount(percentCompleted);
            }
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: apkCurrentFile,
            config
          });

          if (uploadedAppResponse?.status === 200) {
            await getJobStatus({
              variables: {
                data: {
                  jobId:
                    apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId,
                  appId:
                    createApplicationResponse?.data?.createApplication?.data
                      ?.id,
                  ...(!checkboxValue && {
                    androidNote: values?.androidNote
                  }),
                  isSpecificNote: !checkboxValue,
                  ...(checkboxValue && {
                    oneNote: values?.oneReleaseNote
                  }),
                  isDefaultShareableLink: defaultShareLink
                }
              }
            });
          }
        }
      }
      let ipaAppSignedUrlResponse;
      if (ipaCurrentFile) {
        let ipaJobId = null;
        ipaAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: ipaCurrentFile?.name,
              filetype: 'application/octet-stream',
              appId:
                createApplicationResponse?.data?.createApplication?.data?.id,
              fileSize: ipaCurrentFile?.size
            }
          }
        });

        if (ipaAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': `attachment; filename=${ipaCurrentFile?.name}`,
              'x-amz-acl': 'public-read'
            },

            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total
              );
              setIpaPercentCount(percentCompleted);
            }
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: ipaCurrentFile,
            config
          });
          if (uploadedAppResponse?.status === 200)
            ipaJobId =
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId;

          await getJobStatus({
            variables: {
              data: {
                jobId: ipaJobId,
                appId:
                  createApplicationResponse?.data?.createApplication?.data?.id,
                ...(!checkboxValue && {
                  iosNote: values?.iosNote
                }),
                isSpecificNote: !checkboxValue,
                ...(checkboxValue && {
                  oneNote: values?.oneReleaseNote
                }),
                isDefaultShareableLink: defaultShareLink
              }
            }
          });
        }
      }
      setLoading(false);
      dispatch({
        type: 'SET_TEAM',
        data: teamId
      });
      if (apkAppSignedUrlResponse?.data || ipaAppSignedUrlResponse?.data) {
        InviteMembers();
      }
    } catch (error) {
      return error;
    }
  };

  if (teamId === '') {
    history?.push(ROUTES?.CREATE_APP);
  }

  const { required } = formValidatorRules;

  return (
    <Row justify="center" className="onboard-container mb-30">
      <RouterPrompt when={loading} />
      <div className="create-app">
        <div className="create-app-wrapper">
          <div className="create-app-content d-flex justify-between align-center">
            <span className="create-app-text">Create App</span>
            <div className="skip" onClick={InviteMembers}>
              <span>Skip</span>
              <img src={rightArrow} alt="right-arrow" />
            </div>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <fieldset disabled={loading}>
              <Form.Item
                name="appName"
                rules={[{ ...required, message: 'Enter a valid App Name' }]}
              >
                <Input placeholder="Enter App Name" maxLength={50} />
              </Form.Item>
              <Row className="d-flex justify-center align-center">
                <Form.Item>
                  <div className="dragger-parent h-158 mr-15">
                    <Dragger
                      accept=".apk"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={onChangeApkUpload}
                      fileList={apkFileList}
                      maxCount={1}
                      className="dragger-inner p-10 h-158"
                      showUploadList={{ showRemoveIcon: !loading }}
                    >
                      <div className="mb-18">
                        <img src={androidIcon} alt="android-icon" />
                      </div>

                      {apkPercentCount > 0 && (
                        <div className="android-content">
                          {apkPercentCount === 100 ? (
                            <center />
                          ) : (
                            <Progress
                              type="line"
                              size="small"
                              percent={apkPercentCount}
                              className="apk-progress-bar"
                            />
                          )}
                        </div>
                      )}
                      {!apkCurrentFile && (
                        <div>
                          <span className="content-text">
                            Click or Drag & Drop files here (.apk)
                          </span>
                        </div>
                      )}
                    </Dragger>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className="dragger-parent h-158">
                    <Dragger
                      accept=".ipa"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={onChangeIpaUpload}
                      fileList={ipaFileList}
                      maxCount={1}
                      className="dragger-inner h-158 p-10"
                      showUploadList={{ showRemoveIcon: !loading }}
                    >
                      <div className="mb-20">
                        <img src={iosIcon} alt="ios-icon" />
                      </div>

                      {ipaPercentCount > 0 && (
                        <div className="android-content">
                          {ipaPercentCount === 100 ? (
                            <center />
                          ) : (
                            <Progress
                              type="line"
                              size="small"
                              percent={ipaPercentCount}
                            />
                          )}
                        </div>
                      )}
                      {!ipaCurrentFile && (
                        <div className="content-text">
                          <span>Click or Drag & Drop files here (.ipa)</span>
                        </div>
                      )}
                    </Dragger>
                  </div>
                </Form.Item>
              </Row>
              <Row className="justify-between">
                <Form.Item className="checkbox">
                  <Checkbox onChange={checkValue} name="isSpecificNote">
                    One Release Note
                  </Checkbox>
                </Form.Item>
                <Form.Item className="checkbox">
                  <Checkbox
                    onChange={checkDefaultLink}
                    name="isDefaultShareableLink"
                    checked={defaultShareLink}
                  >
                    Default Share Link
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row className="d-flex justify-between align-center">
                {checkboxValue === false ? (
                  <>
                    <Form.Item
                      name="androidNote"
                      className="b-8 mr-15 width-percent-48"
                      rules={[
                        {
                          max: MAX_RELEASE_NOTE_CHARACTER,
                          message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                        }
                      ]}
                    >
                      <HTMLEditor className="html-editor" />
                    </Form.Item>

                    <Form.Item
                      name="iosNote"
                      className="b-8 width-percent-48"
                      rules={[
                        {
                          max: MAX_RELEASE_NOTE_CHARACTER,
                          message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                        }
                      ]}
                    >
                      <HTMLEditor className="html-editor" />
                    </Form.Item>
                  </>
                ) : (
                  <div className="full-width b-8">
                    <Form.Item
                      name="oneReleaseNote"
                      className="full-width b-8"
                      rules={[
                        {
                          max: MAX_RELEASE_NOTE_CHARACTER,
                          message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`
                        }
                      ]}
                    >
                      <HTMLEditor className="html-editor" />
                    </Form.Item>
                  </div>
                )}
              </Row>

              <div className="step-action mt-20 d-flex justify-center">
                <Form.Item shouldUpdate>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={isEmpty(apkCurrentFile || ipaCurrentFile)}
                    loading={loading}
                    className="next-btn"
                  >
                    Next
                    {!loading && (
                      <img
                        src={nextButtonArrow}
                        className="ml-4"
                        alt="next-button-arrow"
                      />
                    )}
                  </Button>
                </Form.Item>
              </div>
            </fieldset>
          </Form>
        </div>
      </div>
    </Row>
  );
};

export default CommonDragger;
