import { filter } from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga4';
import {
  AVATAR_COLOR,
  PERMISSIONS,
  REGEX,
  defaultDateFormat
} from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const covertAppSizeIntoKbOrMB = (appSize) => {
  // if the appSize is less than 0.97, show the app size in 'KB' unit, otherwise show in 'MB' units.
  return appSize < 0.97 ? `${appSize * 1024} KB` : `${appSize} MB`;
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const avatarColor = (name) => {
  return AVATAR_COLOR[name?.charAt(0)?.charCodeAt(0) % AVATAR_COLOR?.length];
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    pattern: REGEX?.EMAIL,
    message: 'Enter a valid email address'
  },
  passwordLength: () => ({
    validator(rule, value) {
      if (!value?.length) {
        return Promise?.resolve();
      }
      if (!REGEX?.INPUT_LENGTH?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Password length must be between 6 to 16!');
      }
      return Promise?.resolve();
    }
  }),
  checkEmailArray: () => ({
    validator(rule, value) {
      if (!value?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.resolve();
      }
      const data = filter(value, (item) => {
        if (REGEX?.EMAIL?.test(item?.toString())) {
          return true;
        }
        return false;
      });

      return data.length === value.length
        ? Promise?.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise?.reject('The input is not valid E-mail');
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  color: () => ({
    validator(rule, value) {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Required');
      }
      if (!REGEX?.COLOR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter valid HEX code');
      }
      return Promise?.resolve();
    }
  }),
  urlPlayStore: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_PLAY_STORE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    }
  }),
  urlPlayStoreAppsOnAir: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_PLAY_STORE_AND_APPSONAIR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    }
  }),
  urlAppStore: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_APP_STORE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    }
  }),
  urlAppStoreAppsOnAir: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_APP_STORE_AND_APPSONAIR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });
};

export const copyToClipboard = (str) => {
  // eslint-disable-next-line no-undef
  const el = document?.createElement('textarea');
  el.value = str;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(el);
  el?.select();
  // eslint-disable-next-line no-undef
  document?.execCommand('copy');
  // eslint-disable-next-line no-undef
  document?.body.removeChild(el);
};

export const checkPermissions = (UserRole = [], action = '') => {
  if (PERMISSIONS?.length === 0) {
    return true;
  }

  return PERMISSIONS?.[action]?.includes(UserRole);
};

const isImageFileType = (type) => type?.indexOf('image/') === 0;

export const previewImage = (file, size = 64) => {
  return new Promise((resolve) => {
    if (!file?.type || !isImageFileType(file?.type)) {
      resolve('');
      return;
    }

    // eslint-disable-next-line no-undef
    const canvas = document?.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${size}px; height: ${size}px; z-index: 9999; display: none;`;
    // eslint-disable-next-line no-undef
    document?.body?.appendChild(canvas);
    const ctx = canvas?.getContext('2d');
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;

      let drawWidth = size;
      let drawHeight = size;
      let offsetX = 0;
      let offsetY = 0;

      if (width < height) {
        drawHeight = height * (size / width);
        offsetY = -(drawHeight - drawWidth) / 2;
      } else {
        drawWidth = width * (size / height);
        offsetX = -(drawWidth - drawHeight) / 2;
      }

      ctx?.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      const dataURL = canvas?.toDataURL();
      // eslint-disable-next-line no-undef
      document?.body?.removeChild(canvas);

      resolve(dataURL);
    };
    // eslint-disable-next-line no-undef
    img.src = window?.URL?.createObjectURL(file);
  });
};

export const googleAnalyticsEvents = (name, params) => {
  ReactGA?.event(name, params);
};
