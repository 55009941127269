import { Modal, Tag } from 'antd';
import React from 'react';
import '../style/appDetails.less';

const ViewProvisionalModal = ({
  ProvisionalNotes = [],
  showProvisionalModal,
  handleVisible,
  data,
  version,
  build
}) => {
  const handleCancel = () => {
    handleVisible(false);
  };

  const isArray = Array?.isArray(ProvisionalNotes);

  return (
    <div className="provisional-wrap">
      <Modal
        title={
          <>
            <span>{data?.getApplication?.appName}</span>
            <Tag>
              <span>Version: {version}</span>
            </Tag>
            <Tag>
              <span>Build: {build}</span>
            </Tag>
          </>
        }
        centered
        footer={null}
        width={578}
        onCancel={handleCancel}
        visible={showProvisionalModal}
      >
        <div className="title">
          Provisional devices
          <span className="data-count ml-14">{ProvisionalNotes?.length}</span>
        </div>
        <div className="modal-content">
          <div className="provisional-content">
            <span>
              {isArray ? (
                ProvisionalNotes?.map((provisionalData) => (
                  <span
                    key={provisionalData}
                    className="d-flex flex-vertical mb-16"
                  >
                    {provisionalData}
                  </span>
                ))
              ) : (
                <span>{ProvisionalNotes || 'No Data Available'}</span>
              )}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewProvisionalModal;
