import { gql } from '@apollo/client';

export const GET_USER_TEAM = gql`
  query getUserTeams {
    getUserTeams {
      id
      teamName
      teamLogo
      role
    }
  }
`;

export const GET_REFRESH_TOKEN = gql`
  query getRefreshToken($refreshToken: String!) {
    getRefreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`;
