import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ApiKey from './Apikey';
import AppDetails from './AppDetails';
import AppList from './AppList';
import AppSettings from './AppSettings';
import AppShare from './AppShare';
import MaintenanceServices from './MaintenanceServices';
import UpdateServices from './UpdateServices';
import UploadRelease from './UploadRelease';

const AppWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.APPS} component={AppList} />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId`}
        component={AppDetails}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.NEW_RELEASE}`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.RELEASE}/:appId`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.CREATE_RELEASE}`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/:appId`}
        component={AppShare}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <UpdateServices {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.MAINTENANCE}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <MaintenanceServices {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.API_KEY}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <ApiKey {...props} />
          </AccessControl>
        )}
      />

      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/:appId`}
        component={AppSettings}
      />
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default AppWrapper;
