import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Card, Col, Input, Row, Spin, Tooltip } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import noGroup from '../../../assets/images/no-group.png';
import defaultIcon from '../../../assets/svg/default-app.svg';
import group from '../../../assets/svg/group.svg';
import { ACTIONS, GA_EVENT, GA_LABEL, LIMIT } from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_GROUP_LIST_WITH_APP } from '../graphql/Queries';
import '../style/teams.less';
import GroupModal from './GroupModal';

let debounceScroll;
let searchDebounce = null;

const GroupsList = ({
  teamId,
  setUpdateTeamsComp,
  updateTeamsComp = false
}) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [groupModal, setGroupModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [record, setRecord] = useState(null);
  const [isEnd, setIsEnd] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [count, setCount] = useState();
  const [loader, setLoader] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [searchData, setSearchData] = useState();

  const [groupLists, { loading }] = useLazyQuery(GET_GROUP_LIST_WITH_APP, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setCount(res?.getGroupsListWithApps?.totalGroups);
      if (scrollFlag) {
        const tempGroupData = [
          ...(groupData || []),
          ...(res?.getGroupsListWithApps?.groupsAppsDetails || [])
        ];
        setGroupData(uniqBy([...tempGroupData], 'id'));
      } else {
        setGroupData(
          uniqBy(res?.getGroupsListWithApps?.groupsAppsDetails, 'id') || []
        );
      }
      setIsEnd(res?.getGroupsListWithApps?.groupsAppsDetails?.length < LIMIT);
      setLoader(false);
    },
    onError() {
      setLoader(false);
    }
  });

  const groupId = groupData?.map((value) => value?.id);

  useEffect(() => {
    groupLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const handleAppSearch = (value) => {
    groupLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT,
          groupName: value
        }
      }
    });
  };

  const handleChange = ({ target: { value } }) => {
    setSearchData(value);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;
    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        groupLists({
          variables: {
            data: {
              teamId,
              skip: groupData?.length,
              limit: LIMIT
            }
          }
        });
      }
    }, 1000);
    debounceScroll();
  };

  const editGroup = (editGroupData) => {
    setGroupModal(true);
    setRecord(editGroupData);
    setIsEdit(true);
  };

  const createGroup = () => {
    setGroupModal(true);
  };

  return (
    <div className="group-list-wrap responsive-cards">
      <Card
        title="Groups"
        className="card-body"
        extra={<div className="count">{count ?? 0}</div>}
      >
        {loader ? (
          <LoaderComponent setHeight="500" />
        ) : (
          <>
            <div className="search-group d-flex justify-between align-center">
              <Input
                prefix={<SearchOutlined className="font-12" />}
                placeholder="Search"
                onChange={handleChange}
                value={searchData}
                allowClear
              />
              <AccessControl allowedPermissions={ACTIONS?.CREATE_GROUP}>
                <div
                  className="add-group"
                  onClick={() => {
                    googleAnalyticsEvents(GA_EVENT?.CREATE_NEW_GROUP, {
                      label: GA_LABEL?.CREATE_NEW_GROUP,
                      member_id: currentUser?.id
                    });
                    setGroupModal(true);
                  }}
                >
                  <Avatar size={38} shape="circle" className="group-add-icon">
                    <PlusOutlined className="plus-color" />
                  </Avatar>
                </div>
              </AccessControl>
            </div>
            <div className="group-list-title d-flex justify-between align-center">
              <span>Groups</span>
              <div className="add-member">
                <span>Shared Apps</span>
              </div>
            </div>
            <div className="group-list" onScroll={handleScroll}>
              {groupData?.length === 0 ? (
                <div className="no-group-div">
                  <div className="no-group-img">
                    <img src={noGroup} alt="no-group" />
                  </div>
                  <div className="no-group-content">
                    <h1>Create Groups to Share Faster</h1>
                    <AccessControl allowedPermissions={ACTIONS?.CREATE_GROUP}>
                      <div className="no-group-btn">
                        <Button type="primary" onClick={createGroup}>
                          Create Group
                        </Button>
                      </div>
                    </AccessControl>
                  </div>
                </div>
              ) : (
                groupData?.map((details) => {
                  return (
                    <div
                      key={details?.id}
                      className="group-list-row"
                      onClick={() => editGroup(details)}
                    >
                      <Row className="group-lists">
                        <Col md={2} lg={2} xl={2} xxl={2}>
                          <Avatar
                            size={38}
                            key={details?.firstName}
                            src={group}
                          />
                        </Col>
                        <Col md={16} lg={16} xl={16} xxl={16}>
                          <div>
                            <span className="font-500 ml-6">
                              {details?.groupName}
                            </span>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          className="d-flex justify-end"
                        >
                          <Avatar.Group
                            maxCount={2}
                            maxStyle={{
                              color: '#585E75',
                              backgroundColor: '#F2F4F7',
                              height: '24px',
                              width: '24px',
                              lineHeight: '24px',
                              fontSize: '12px'
                            }}
                          >
                            {details?.applicationList?.map((value) => {
                              return (
                                <Tooltip title={value?.appName} key={value?.id}>
                                  <Avatar
                                    size={24}
                                    key={value?.id}
                                    src={value?.appLogo || defaultIcon}
                                    alt="logo"
                                  />
                                </Tooltip>
                              );
                            })}
                          </Avatar.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              )}
              {loading && (
                <div className="ant-loader">
                  <Spin />
                </div>
              )}
            </div>
          </>
        )}
        {groupModal && (
          <GroupModal
            groupModal={groupModal}
            handleModalVisible={setGroupModal}
            teamId={teamId}
            isEdit={isEdit}
            setLoaderList={setLoader}
            setIsEdit={setIsEdit}
            record={record}
            groupId={groupId}
            groupLists={groupLists}
            setUpdateTeamsComp={setUpdateTeamsComp}
            updateTeamsComp={updateTeamsComp}
          />
        )}
      </Card>
    </div>
  );
};

export default GroupsList;
