import { gql } from '@apollo/client';

export const GET_APPS = gql`
  query getAppsList($data: ApplicationFilter!) {
    getAppsList(data: $data) {
      appsDetails {
        id
        appName
        appLogo
        updatedAt
        dashboard {
          totalUsersUpload
          totalAppsDownload
          latestReleaseDate
          remainingDays
        }
      }
    }
  }
`;

export const GET_APP = gql`
  query getApplication($appId: ID!) {
    getApplication(appId: $appId) {
      appName
      appLogo
      appCreatedBy
      androidUpdateLink
      iosUpdateLink
    }
  }
`;

export const GET_APP_UPGRADE_DETAIL = gql`
  query getAppUpgradeDetail($appId: ID!) {
    getAppUpgradeDetail(appId: $appId) {
      isIosUpdate
      iosBuildNumber
      iosMinBuildVersion
      iosUpdateLink
      isIosForcedUpdate
      isAndroidUpdate
      androidBuildNumber
      androidMinBuildVersion
      androidUpdateLink
      isAndroidForcedUpdate
    }
  }
`;

export const GET_APP_MAINTENANCE_DETAIL = gql`
  query getAppMaintenanceDetail($appId: ID!) {
    getAppMaintenanceDetail(appId: $appId) {
      maintenanceTitle
      maintenanceDescription
      maintenanceImage
      textColorCode
      backgroundColorCode
      isMaintenance
    }
  }
`;

export const GET_APP_RELEASE_DETAILS = gql`
  query getApplicationsReleaseDetails($data: GetApplicationDetailsInput!) {
    getApplicationsReleaseDetails(data: $data) {
      applicationData {
        id
        appName
        appLogo
        applicationDetails {
          id
          appPlatform
          appVersion
          appTag
          appBuild
          appMetadata
          isSpecificNote
          androidReleaseNote
          iosReleaseNote
          oneReleaseNote
          applicationUniqueId
          downloads
          createdAt
          remainingDays
          isPrivate
          appStorageUrl
          linkCheck
          createdBy
          isArchived
        }
      }
      totalReleasesCount
      releasesArchivedCount
    }
  }
`;

export const GET_PLATFORM_SPECIFIC_RELEASE = gql`
  query getPlatformSpecificLatestReleaseResponse(
    $id: ID!
    $platform: PlatformType!
  ) {
    getPlatformSpecificLatestRelease(id: $id, platform: $platform) {
      id
      appVersion
      appTag
      appStorageUrl
      applicationUniqueId
      platformSpecificDashboard {
        totalPlatformSpecificUsersUpload
        totalPlatformSpecificAppsDownload
      }
    }
  }
`;

export const GET_SHARABLE_LINKS = gql`
  query getSharableLink($data: GetShareableLinksData!) {
    getShareableLinks(data: $data) {
      id
      link
      isPrivate
      remainingDays
      subLinkId
      applicationUniqueId
      version
      build
      linkExpiration
    }
  }
`;

export const GET_APP_MEMBER_GROUP_LIST = gql`
  query getAppMembersGroupsList($data: ShareListFilter!) {
    getAppMembersGroupsList(data: $data)
  }
`;

export const GET_RELEASE_FEEDBACK = gql`
  query getReleaseFeedback($data: GetReleaseFeedbackInput!) {
    getReleaseFeedback(data: $data) {
      feedbacks {
        id
        feedback
        feedbackImage
        feedbackVideo
        blurHash
        isResolved
        releaseId
        createdAt
        creator {
          id
          profileImage
          firstName
          lastName
          email
        }
        resolver {
          userId
          profileImage
          firstName
          lastName
          resolveComment
          resolvedAt
        }
      }
      feedbackCount
    }
  }
`;
