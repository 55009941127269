import { useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Upload,
  message
} from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { filter, map, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import editIcon from '../../../assets/images/edit-icon.png';
import teamIcon from '../../../assets/images/team-icon.png';
import { ACTIONS, ALL_IMAGE_UPLOAD } from '../../../common/constants';
import { avatarColor, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_SIGNED_URL } from '../../../modules/onboard/graphql/mutation';
import { UPDATE_TEAM } from '../../../modules/teams/graphql/Mutation';
import {
  GET_MEMBER_APPS_LIST,
  GET_TEAM
} from '../../../modules/teams/graphql/Queries';
import './workspaceSettings.less';

const { required } = formValidatorRules;

const WorkspaceSettings = () => {
  const {
    state: { userTeams },
    dispatch
  } = useContext(AppContext);

  const teamID = useParams();
  const [form] = Form?.useForm();

  const [currentFile, setCurrentFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState([]);
  const [initialValue, setInitialValue] = useState(null);
  const [cropImage, setCropImage] = useState();
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false
  });

  const { loading } = useQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: teamID?.teamId
    },
    onCompleted: (res) => {
      if (!initialValue) {
        setInitialValue({
          teamName: res?.getTeam?.teamDetails?.teamName
        });
      } else {
        form?.setFieldsValue({
          teamName: res?.getTeam?.teamDetails?.teamName
        });
      }

      if (res?.getTeam?.teamDetails?.teamLogo) {
        setImageUpload([
          {
            url: res?.getTeam?.teamDetails?.teamLogo
          }
        ]);
      } else {
        setImageUpload([]);
      }
    },
    onError: () => {}
  });

  const { data: memberData } = useQuery(GET_MEMBER_APPS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        teamId: teamID?.teamId
      }
    },
    onError(error) {
      return error;
    }
  });

  const [teamUpdate] = useMutation(UPDATE_TEAM, {
    onCompleted(res) {
      if (res?.updateTeam?.teamData?.teamLogo) {
        setImageUpload([{ url: res?.updateTeam?.teamData?.teamLogo }]);
      } else {
        setImageUpload([]);
      }
      form?.setFieldsValue({
        teamName: res?.updateTeam?.teamData?.teamName
      });
      setBtnLoading(false);
      setCurrentFile(null);
      setCropImage(null);
    },
    onError() {
      setBtnLoading(false);
    }
  });

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    }
  });

  useEffect(() => {
    if (teamID?.teamId) {
      const value = filter(userTeams, (val) => val?.id === teamID?.teamId);
      dispatch({
        type: 'SET_CURRENT_ROLE',
        data: value
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID?.teamId, userTeams]);

  const handleUpload = async (values) => {
    const { selectedImage } = state;
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true
    });

    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type
          }
        }
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read'
        }
      };

      try {
        await axios?.put(
          res?.data?.getSignedUrl?.signedURL,
          currentFile,
          config
        );
        const { teamName } = values;
        const data = teamName
          ? {
              teamId: teamID?.teamId,
              teamName,
              teamLogo: res?.data?.getSignedUrl?.fileUrl
            }
          : {
              teamLogo: res?.data?.getSignedUrl?.fileUrl
            };
        await teamUpdate({
          variables: {
            data
          }
        });
        setBtnLoading(false);
      } catch (err) {
        return err;
      }
    } else {
      teamUpdate({
        variables: {
          data: {
            teamId: teamID?.teamId,
            teamName: values?.teamName,
            teamLogo: imageUpload?.[0]?.url || null
          }
        }
      });
    }
    dispatch({
      type: 'SET_FETCH_USER_TEAMS',
      data: true
    });
  };

  const onImageCrop = async (info) => {
    setCropImage(info);
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url }
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(cropImage);
    } else if (info?.file?.status === 'removed') {
      setCropImage(null);
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className="workspace-card">
        {!initialValue ? (
          <LoaderComponent />
        ) : (
          <div className="workspace-card-content">
            <Card title="Workspace Settings" bordered>
              <fieldset
                disabled={
                  !AccessControl({
                    allowedPermissions: ACTIONS?.UPDATE_WORKSPACE
                  })
                }
              >
                <Form
                  name="workspace-settings"
                  layout="vertical"
                  form={form}
                  onFinish={handleUpload}
                  initialValues={initialValue}
                >
                  <Form.Item name="teamLogo">
                    <div className="workspace-upload">
                      <ImgCrop
                        showGrid
                        rotationSlider
                        onModalOk={onImageCrop}
                        modalOk="Save"
                      >
                        <Upload
                          listType="picture-card"
                          fileList={imageUpload}
                          onChange={onChangeImageUpload}
                          maxCount={1}
                          showUploadList={{
                            showPreviewIcon: false,
                            removeIcon: !AccessControl({
                              allowedPermissions: ACTIONS?.UPDATE_WORKSPACE
                            })
                          }}
                          accept=".jpeg,.jpg,.png"
                          customRequest={({ onSuccess }) => {
                            onSuccess('ok');
                          }}
                        >
                          {!imageUpload?.length && (
                            <img
                              src={editIcon}
                              alt="icon"
                              className="workspace-icon"
                            />
                          )}
                        </Upload>
                      </ImgCrop>
                      <span className="workspace-heading font-12">
                        Update Workspace Image
                      </span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="teamName"
                    label="Workspace Name"
                    rules={[
                      {
                        ...required,
                        message: 'Enter a valid Workspace Name'
                      }
                    ]}
                  >
                    <Input placeholder="Enter workspace name" maxLength={50} />
                  </Form.Item>
                  <Row className="workspace-owner-content">
                    <Col span={12}>
                      <span>Owner :</span>
                    </Col>
                    <Col span={12}>
                      <span>Total Members :</span>
                    </Col>
                  </Row>
                  <div className="workspace-container">
                    <Row className="workspace-row" gutter={12}>
                      <Col span={12} className="workspace-col">
                        {map(
                          memberData?.getMemberAppsList?.appsDetails,
                          (detail) => {
                            if (detail?.role === 'OWNER') {
                              return (
                                <div
                                  className="workspace-data"
                                  key={detail?.id}
                                >
                                  <Avatar
                                    size={37}
                                    shape="circle"
                                    style={{
                                      backgroundColor: avatarColor(
                                        detail?.invitedUserEmail
                                      )
                                    }}
                                  >
                                    {detail?.invitedUserEmail
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                  </Avatar>
                                  <div className="workspace-data-content">
                                    <div className="name-tags">
                                      {trim(
                                        `${detail?.firstName || ''} ${
                                          detail?.lastName || ''
                                        }`
                                      )}
                                    </div>
                                    <span className="workspace-content font-12">
                                      {detail?.invitedUserEmail}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          }
                        )}
                      </Col>
                      <Col span={12}>
                        <img
                          src={teamIcon}
                          alt="member-icon"
                          className="workspace-image"
                        />
                        <span className="workspace-content member-count">
                          {memberData?.getMemberAppsList?.count}
                        </span>
                      </Col>
                    </Row>
                    {AccessControl({
                      allowedPermissions: ACTIONS?.UPDATE_WORKSPACE
                    }) && (
                      <>
                        <Divider className="card-divider" />
                        <Row className="button-row">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            className="save-btn"
                            loading={btnLoading}
                          >
                            Save
                          </Button>
                        </Row>
                      </>
                    )}
                  </div>
                </Form>
              </fieldset>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkspaceSettings;
