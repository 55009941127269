import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Error404 from '../Error404';
import PrivateRoute from '../PrivateRoute';
import { ACTIONS, ROUTES, WORKSPACE_FEATURES_TAB } from '../common/constants';
import AccessControl from '../components/AccessControl';
import AppWrapper from '../modules/apps';
import ProfileSettings from '../modules/auth/ProfileSettings';
import Integrations from '../modules/integrations/Integrations';
import MainWrapper from '../modules/main';
import OnboardWrapper from '../modules/onboard';
import TeamWrapper from '../modules/teams';
import WorkspaceSettings from './components/header/WorkspaceSettings';

const ContentRoutes = () => {
  const {
    state: { workspaceFeatures }
  } = useContext(AppContext);
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={ROUTES?.APPS} />}
        />
        <Route path={ROUTES?.ONBOARD} component={OnboardWrapper} />
        <Route
          exact
          path={ROUTES?.CREATE_APP}
          render={(props) => (
            <AccessControl
              allowedPermissions={ACTIONS?.CREATE_APP}
              showNoAccess
            >
              <MainWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route path={ROUTES?.APPS} component={AppWrapper} />
        <Route path={ROUTES?.TEAMS} component={TeamWrapper} />
        {workspaceFeatures?.includes(WORKSPACE_FEATURES_TAB?.INTEGRATIONS) && (
          <Route path={ROUTES?.INTEGRATIONS} component={Integrations} />
        )}
        <PrivateRoute
          exact
          path={ROUTES?.PROFILE_SETTINGS}
          component={ProfileSettings}
        />
        <PrivateRoute
          path={`${ROUTES?.WORKSPACE_SETTINGS}/:teamId`}
          component={WorkspaceSettings}
        />
        <Route exact path="*" component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
