import { useQuery } from '@apollo/client';
import { Layout } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import LoaderComponent from '../components/LoaderComponent';
import { GET_USER } from '../modules/users/graphql/Queries';
import './App.less';
import ContentRoutes from './ContentRoutes';
import AppHeader from './components/header/AppHeader';

const { Content } = Layout;

const App = () => {
  const {
    dispatch,
    state: { loading }
  } = useContext(AppContext);

  useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.user
      });
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    },
    onError() {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    }
  });
  if (loading) return <LoaderComponent />;
  return (
    <Layout className="gx-app-layout">
      <AppHeader />
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          <ContentRoutes />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
