import { gql } from '@apollo/client';

export const INVITE_MEMBER_IN_TEAM = gql`
  mutation inviteMemberInTeam($teamId: ID!, $data: [InviteMemberInTeamInput]!) {
    inviteMemberInTeam(teamId: $teamId, data: $data) {
      message
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($groupName: String!, $teamId: ID!) {
    createGroup(groupName: $groupName, teamId: $teamId) {
      groupData {
        id
        groupName
        teamId
        groupCreatedBy
      }
      message
    }
  }
`;

export const INVITE_GROUP_MEMBER = gql`
  mutation inviteGroupMember($groupIds: [ID]!, $email: [String]!) {
    inviteGroupMember(groupIds: $groupIds, email: $email) {
      message
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation updateTeamMember(
    $memberId: ID!
    $role: RoleType!
    $groupIds: [ID!]
  ) {
    updateTeamMember(memberId: $memberId, role: $role, groupIds: $groupIds) {
      id
      role
      invitedUserEmail
      invitedBy
      message
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation removeTeamMember($teamId: ID!, $memberId: ID!) {
    removeTeamMember(teamId: $teamId, memberId: $memberId) {
      message
    }
  }
`;
export const UPDATE_TEAM = gql`
  mutation updateTeam($data: TeamUpdateInput!) {
    updateTeam(data: $data) {
      message
      teamData {
        id
        teamName
        teamLogo
      }
    }
  }
`;

export const DELETE_GROUP_MEMBER = gql`
  mutation removeGroupMember($groupMemberId: ID!) {
    removeGroupMember(groupMemberId: $groupMemberId) {
      message
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) {
      message
    }
  }
`;
