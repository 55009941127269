import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Switch, Tabs, Tree } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import defaultIcon from '../../../assets/svg/default-app.svg';
import { MODAL_WIDTH } from '../../../common/constants';
import {
  CREATE_INTEGRATION_CONFIG,
  CREATE_INTEGRATION_EVENT_WITH_APP_EXCLUDE,
  UPDATE_INTEGRATION
} from '../graphql/Mutations';
import {
  GET_APPS,
  GET_INTEGRATIONS_EVENTS_LIST,
  GET_USER_EXCLUDED_APPS,
  GET_USER_INTEGRATION_CONFIG,
  GET_USER_INTEGRATION_CONFIG_EVENTS
} from '../graphql/Queries';

const { TreeNode } = Tree;
let debounceScroll;
const APP_LIST_LIMIT = 20;
const { Option } = Select;

function AppConnectPopup({
  appConnectPopupData,
  handleClose,
  teamId,
  getIntegrationList
}) {
  const [isButtonLoader, setIsButtonLoader] = useState(false);
  const [step, setStep] = useState(null);
  const [form] = Form?.useForm();
  const [eventList, setEventList] = useState([]);
  const [eventInputList, setEventInputList] = useState([]);
  const [configId, setConfigId] = useState(null);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [appData, setAppData] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [excludedAppsList, setExcludedAppsList] = useState([]);
  const [getIntegrationEvents] = useLazyQuery(GET_INTEGRATIONS_EVENTS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setEventList(res?.events?.data);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [executeSearch] = useLazyQuery(GET_APPS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        setAppData([...appData, ...res?.getAppsList?.appsDetails]);
        setScrollFlag(false);
      } else {
        setAppData([...res?.getAppsList?.appsDetails]);
      }
      if (res?.getAppsList?.appsDetails?.length < APP_LIST_LIMIT) {
        setIsEnd(true);
      }
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [getUserIntegrationConfig] = useLazyQuery(GET_USER_INTEGRATION_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const values = {};
      appConnectPopupData?.popupData?.config?.forEach((item) => {
        const getValue = res?.integrationConfig?.data?.configs?.[item?.key];
        values[`${item.key}`] = getValue;
      });
      form?.setFieldsValue(values);
      setConfigId(appConnectPopupData?.popupData?.integrationConfigs?.[0].id);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [getUserIntegrationConfigEvents] = useLazyQuery(
    GET_USER_INTEGRATION_CONFIG_EVENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const eventsArray = res?.integrationEvents?.data?.map(
          (item) => item?.eventKey
        );
        setEventInputList(eventsArray);
        setIsButtonLoader(false);
      },
      onError() {
        setIsButtonLoader(false);
      }
    }
  );

  const [getUserExcludedApps] = useLazyQuery(GET_USER_EXCLUDED_APPS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const excludeAppsIds = res?.getIntegrationExcludeApp?.data?.map(
        (item) => item?.application?.id
      );
      setExcludedAppsList(excludeAppsIds);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  useEffect(() => {
    setAppData([]);
    const stepDecider =
      appConnectPopupData?.popupData?.action === 'REDIRECTION' ? 2 : 1;
    if (stepDecider === 2) {
      getIntegrationEvents();
      executeSearch({
        variables: {
          data: {
            teamId,
            skip: appData?.length,
            limit: APP_LIST_LIMIT
          }
        }
      });
    }
    setStep(stepDecider);
    form?.resetFields();
    if (appConnectPopupData?.isEdit) {
      getUserIntegrationConfig({
        variables: {
          where: {
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id,
            teamId: teamId
          }
        }
      });
      getUserIntegrationConfigEvents({
        variables: {
          where: {
            teamId: teamId,
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id
          }
        }
      });
      getUserExcludedApps({
        variables: {
          where: {
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0]?.id,
            teamId
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConnectPopupData?.isEdit]);

  const [createIntegrationConfig] = useMutation(CREATE_INTEGRATION_CONFIG, {
    onCompleted: (res) => {
      getIntegrationEvents();
      executeSearch({
        variables: {
          data: {
            teamId,
            skip: appData?.length,
            limit: APP_LIST_LIMIT
          }
        }
      });
      setStep(2);
      setConfigId(res?.createIntegrationConfig?.data?.id);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [updateIntegrationConfig] = useMutation(UPDATE_INTEGRATION, {
    onCompleted() {
      getIntegrationEvents();
      setStep(2);
      setIsButtonLoader(false);
      executeSearch({
        variables: {
          data: {
            teamId,
            skip: appData?.length,
            limit: APP_LIST_LIMIT
          }
        }
      });
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [submitEventsList] = useMutation(
    CREATE_INTEGRATION_EVENT_WITH_APP_EXCLUDE,
    {
      onCompleted() {
        handleClose();
        setAppData([]);
        setStep(1);
        getIntegrationList();
        setIsButtonLoader(false);
      },
      onError() {
        setIsButtonLoader(false);
      }
    }
  );

  const handleIntegrationForm = async (values) => {
    setIsButtonLoader(true);
    if (appConnectPopupData?.isEdit) {
      updateIntegrationConfig({
        variables: {
          data: {
            configs: values
          },
          where: {
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id
          }
        }
      });
    } else {
      createIntegrationConfig({
        variables: {
          data: {
            integrationKey: appConnectPopupData?.popupData?.key,
            configs: values,
            teamId: teamId
          }
        }
      });
    }
  };

  const handleCommonSave = () => {
    if (step === 1) {
      form?.submit();
    } else {
      setIsButtonLoader(true);
      submitEventsList({
        variables: {
          data: {
            applicationIds: excludedAppsList,
            eventKeys: eventInputList,
            integrationConfigId: configId
          }
        }
      });
    }
  };

  const handleExcludedApp = (e, appId) => {
    const excludedApps = [...excludedAppsList];
    if (!e) {
      excludedApps?.push(appId);
    } else {
      excludedApps.splice(
        excludedApps?.findIndex((item) => item === appId),
        1
      );
    }
    setExcludedAppsList([...excludedApps]);
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 15;

      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        executeSearch({
          variables: {
            data: {
              teamId,
              skip: appData?.length,
              limit: APP_LIST_LIMIT
            }
          }
        });
      }
    }, 500);
    debounceScroll();
  };

  return (
    <>
      <Modal
        width={MODAL_WIDTH}
        className="int-popup-model"
        visible={appConnectPopupData?.isPopupOpen}
        onCancel={handleClose}
        okText={step === 1 ? 'Save & Next' : 'Finish'}
        footer={false}
      >
        {step === 1 ? (
          <>
            <h4 className="main-title">
              Connect with {appConnectPopupData?.popupData?.name}
            </h4>
            <div className="int-popup-content">
              <>
                <div className="title">
                  <p className="mb-0">
                    Follow these steps to configure webhook:
                  </p>
                </div>
                <div className="points">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: appConnectPopupData?.popupData?.helpContent
                    }}
                  />
                </div>
              </>
              <div className="inputs">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleIntegrationForm}
                >
                  {appConnectPopupData?.popupData?.config?.length > 0 &&
                    appConnectPopupData?.popupData?.config?.map((item) => (
                      <>
                        {item?.inputType === 'string' ? (
                          <>
                            <Form.Item
                              name={item?.key}
                              key={item?.key}
                              className="input-label"
                              label={item?.label}
                              rules={[
                                {
                                  required:
                                    // eslint-disable-next-line no-unneeded-ternary
                                    item?.required === 'true' ? true : false,
                                  message: `${item?.label} is Required`
                                },
                                {
                                  pattern: item?.regex?.replace(/^\/|\/$/g, ''),
                                  message: `Please enter valid ${item?.label}`
                                }
                              ]}
                            >
                              <Input placeholder="https:/yourwebsiteurl.com/webhook" />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name={item?.key}
                              key={item?.key}
                              label={item?.label}
                              rules={[
                                {
                                  required:
                                    // eslint-disable-next-line no-unneeded-ternary
                                    item?.required === 'true' ? true : false,
                                  message: `${item?.label} is Required`
                                }
                              ]}
                            >
                              <Select placeholder="Please Select Type">
                                {item?.options?.map((option) => (
                                  <Option key={option?.key} value={option?.key}>
                                    {option?.value}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        )}
                      </>
                    ))}
                </Form>
              </div>
            </div>
          </>
        ) : (
          <Tabs>
            <Tabs.TabPane tab="Events" key="item-1">
              <div className="event-container">
                <Tree
                  checkable
                  expandedKeys={['SELECT_ALL']}
                  onCheck={(checkedKeys) =>
                    setEventInputList(
                      checkedKeys?.filter((key) => key !== 'SELECT_ALL')
                    )
                  }
                  checkedKeys={eventInputList}
                >
                  <TreeNode title="Select All" key="SELECT_ALL">
                    {eventList?.length > 0 &&
                      eventList?.map((item) => (
                        <TreeNode
                          title={item?.name}
                          key={item?.key}
                          dataRef={item}
                        />
                      ))}
                  </TreeNode>
                </Tree>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Allowed Apps" key="item-2">
              <div className="app-container" onScroll={handleScroll}>
                {appData?.map((item) => {
                  const id = item?.id;
                  const appName = item?.appName;
                  const appLogo = item?.appLogo;
                  return (
                    <div key={id} className="main-app-container">
                      <div className="app-prop">
                        <img
                          className="app-img"
                          src={appLogo || defaultIcon}
                          alt={appName}
                        />
                        <div className="app-name">{appName}</div>
                      </div>
                      <Switch
                        checked={!excludedAppsList.includes(id)}
                        onChange={(e) => handleExcludedApp(e, id)}
                      />
                    </div>
                  );
                })}
              </div>
            </Tabs.TabPane>
          </Tabs>
        )}
        <div className="integration-popup-footer">
          <Button onClick={handleClose}>Cancel</Button>
          {step === 2 && appConnectPopupData?.popupData?.action === 'POPUP' && (
            <Button onClick={() => setStep(1)}>Previous</Button>
          )}
          <Button
            disabled={isButtonLoader}
            type="primary"
            onClick={handleCommonSave}
          >
            {step === 1 ? 'Save & Next' : 'Save'}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AppConnectPopup;
