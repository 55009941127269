import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import onboardFrame from '../../assets/images/onboard-frame.png';
import cameraIcon from '../../assets/svg/camer-icon.svg';
import { ALL_IMAGE_UPLOAD, ROUTES } from '../../common/constants';
import { formValidatorRules, getBase64 } from '../../common/utils';
import { CREATE_TEAM, GET_SIGNED_URL } from './graphql/mutation';
import './style/workspace.less';

const WorkSpace = () => {
  const [form] = Form?.useForm();
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false
  });

  const [currentFile, setCurrentFile] = useState(null);
  const { selectedImage } = state;
  const [imageUpload, setImageUpload] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState();

  const { dispatch } = useContext(AppContext);

  const history = useHistory();

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
      setLoading(false);
    }
  });

  const [team] = useMutation(CREATE_TEAM, {
    onError(error) {
      setLoading(false);
      return error;
    }
  });

  const redirectToNextStep = () => {
    const path = `${ROUTES?.ONBOARD}${ROUTES?.APP_CREATE}`;
    history?.push(path);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData?.append('file', selectedImage);

    setState({
      ...state,
      uploading: true
    });
  };

  const onImageCrop = async (info) => {
    setCropImage(info);
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url }
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(cropImage);

      const preview = await getBase64(info?.fileList?.[0]?.originFileObj);
      setPreviewImage(preview);
    } else {
      setCropImage(null);
      setCurrentFile(null);
      setImageUpload([]);
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleUpload = async (values) => {
    setLoading(true);
    if (currentFile) {
      const signedUrlResponse = await getSignedUrl({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type
          }
        }
      });

      if (signedUrlResponse?.data) {
        const config = {
          headers: {
            'Content-Type': currentFile?.type,
            'x-amz-acl': 'public-read'
          }
        };
        const uploadAppIcon = axios?.put(
          signedUrlResponse?.data?.getSignedUrl.signedURL,
          currentFile,
          config
        );

        if (uploadAppIcon === 100) {
          message?.success('Icon Uploaded');
        }

        try {
          const teamResponse = await team({
            variables: {
              teamName: values?.teamName,
              teamLogo: signedUrlResponse?.data?.getSignedUrl?.fileUrl || null
            }
          });
          if (teamResponse?.data) {
            setLoading(false);
            dispatch({
              type: 'SET_TEAM',
              data: teamResponse?.data?.createTeam?.teamData?.id
            });
            dispatch({
              type: 'SET_NEW_TEAM',
              data: true
            });
            redirectToNextStep();
          }
        } catch (error) {
          setLoading(false);
          return error;
        }
      }
      return;
    }

    const teamResponse = await team({
      variables: {
        teamName: values?.teamName
      }
    });
    try {
      if (teamResponse?.data) {
        dispatch({
          type: 'SET_TEAM',
          data: teamResponse?.data?.createTeam?.teamData?.id
        });
        dispatch({
          type: 'SET_NEW_TEAM',
          data: true
        });
        redirectToNextStep();
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const { required } = formValidatorRules;

  return (
    <Row justify="center" className="mt-35 onboard-container">
      <div className="workspace-wrapper">
        <div className="step-content h-192 full-width mt-35 p-24 b-8">
          <span className="workspace-text">Name Your Workspace</span>
          <div className="step-content-icon h-30 mb-8 d-flex mt-24">
            <ImgCrop
              showGrid
              rotationSlider
              onModalOk={onImageCrop}
              modalOk="Save"
            >
              <Upload
                maxCount={1}
                onChange={onChangeImageUpload}
                fileList={imageUpload}
                showUploadList={false}
                className="apk-uploader"
                customRequest={({ onSuccess }) => {
                  onSuccess('ok');
                }}
              >
                {previewImage ? (
                  <img src={previewImage} alt="app-icon" className="h-16" />
                ) : (
                  <img src={cameraIcon} alt="camera-icon" />
                )}
              </Upload>
            </ImgCrop>
            <span className="image-text">Set a Workspace Image</span>
          </div>
          <div className="step-content-form">
            <div className="step-content-input">
              <Form
                form={form}
                onFinish={handleUpload}
                className="d-flex justify-between"
              >
                <Form.Item
                  name="teamName"
                  rules={[
                    { ...required, message: 'Enter a valid Workspace Name' }
                  ]}
                  className="b-8 workspace-input"
                >
                  <Input placeholder="Workspace Name" maxLength={50} />
                </Form.Item>
                <Form.Item shouldUpdate className="step-action">
                  {() => (
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="next-btn"
                      disabled={
                        !form?.isFieldsTouched(true) ||
                        !!form
                          ?.getFieldsError()
                          ?.filter(({ errors }) => errors?.length)?.length
                      }
                      loading={loading}
                    >
                      Next
                      {!loading && <ArrowRightOutlined />}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className="team mt-45">
          <div className="team-box">
            <img src={onboardFrame} alt="OnboardFrame" />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default WorkSpace;
