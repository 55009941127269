import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppNameHeader from '../../components/AppNameHeader';
import LoaderComponent from '../../components/LoaderComponent';
import AppServices from './AppServices';
import UpdateApplication from './UpdateApplication';
import UpdatePreview from './UpdatePreview';
import { GET_APP_UPGRADE_DETAIL } from './graphql/Queries';
import './updateservices.less';

const UpdateServices = () => {
  const appID = useParams();
  const id = appID?.appId;

  const [androidCollapse, setAndroidCollapse] = useState(null);
  const [iosCollapse, setIosCollapse] = useState(null);

  const [androidChecked, setAndroidChecked] = useState(false);
  const [iosChecked, setIosChecked] = useState(false);

  const [androidInitialValues, setAndroidInitialValues] = useState(null);
  const [iosInitialValues, setIosInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);

  const [fetchUpgradeDetails] = useLazyQuery(GET_APP_UPGRADE_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      appId: id
    },
    onCompleted(res) {
      if (res?.getAppUpgradeDetail?.isAndroidUpdate) {
        setAndroidCollapse(true);
        setAndroidInitialValues({
          androidBuildNumber: res?.getAppUpgradeDetail?.androidBuildNumber,
          androidMinBuildVersion:
            res?.getAppUpgradeDetail?.androidMinBuildVersion,
          androidUpdateLink: res?.getAppUpgradeDetail?.androidUpdateLink
        });
      }
      if (res?.getAppUpgradeDetail?.isIosUpdate) {
        setIosCollapse(true);
        setIosInitialValues({
          iosBuildNumber: res?.getAppUpgradeDetail?.iosBuildNumber,
          iosMinBuildVersion: res?.getAppUpgradeDetail?.iosMinBuildVersion,
          iosUpdateLink: res?.getAppUpgradeDetail?.iosUpdateLink
        });
      }
      if (res?.getAppUpgradeDetail?.isAndroidForcedUpdate) {
        setAndroidChecked(true);
      }
      if (res?.getAppUpgradeDetail?.isIosForcedUpdate) {
        setIosChecked(true);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchUpgradeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppNameHeader />
      <AppServices>
        {loading ? (
          <LoaderComponent spinning setHeight="200" />
        ) : (
          <div className="d-flex justify-between">
            <Row className="update-content-row">
              <Col span={24}>
                <UpdateApplication
                  androidCollapse={androidCollapse}
                  iosCollapse={iosCollapse}
                  setAndroidCollapse={setAndroidCollapse}
                  setIosCollapse={setIosCollapse}
                  androidChecked={androidChecked}
                  setAndroidChecked={setAndroidChecked}
                  iosChecked={iosChecked}
                  setIosChecked={setIosChecked}
                  androidInitialValues={androidInitialValues}
                  iosInitialValues={iosInitialValues}
                  fetchUpgradeDetails={fetchUpgradeDetails}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="update-content-preview">
                  <UpdatePreview
                    androidCollapse={androidCollapse}
                    iosCollapse={iosCollapse}
                    androidChecked={androidChecked}
                    iosChecked={iosChecked}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </AppServices>
    </>
  );
};

export default UpdateServices;
