import { gql } from '@apollo/client';

export const CREATE_TEAM = gql`
  mutation createTeam($teamName: String!, $teamLogo: String) {
    createTeam(teamName: $teamName, teamLogo: $teamLogo) {
      message
      teamData {
        id
        teamName
        teamLogo
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation getSignedUrl($data: SignedURLInput!) {
    getSignedUrl(data: $data) {
      fileName
      fileUrl
      signedURL
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication($data: AppDataInput!) {
    createApplication(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
    }
  }
`;

export const GET_APP_SIGNED_URL = gql`
  mutation generateAppSignedUrl($data: AppSignedURLInput!) {
    generateAppSignedUrl(data: $data) {
      signedURL
      jobId
    }
  }
`;

export const RELEASE_UPDATE_JOB_STATUS = gql`
  mutation releaseUpdateJobStatus($data: ReleasesInput!) {
    releaseUpdateJobStatus(data: $data) {
      message
      appUniqueCode
    }
  }
`;

export const INVITE_MEMBERS = gql`
  mutation inviteMemberInTeam(
    $members: [InviteMemberInTeamInput]!
    $teamId: ID!
  ) {
    inviteMemberInTeam(data: $members, teamId: $teamId) {
      message
    }
  }
`;
