import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Tag,
  message
} from 'antd';
import { filter, find, get, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import deleteIcon from '../../assets/images/delete-icon.png';
import { SearchIcon } from '../../assets/svg';
import addMember from '../../assets/svg/add-member.svg';
import groupIcon from '../../assets/svg/group-icon.svg';
import { ACTIONS, ROUTES } from '../../common/constants';
import { avatarColor } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import AppNameHeader from '../../components/AppNameHeader';
import LoaderComponent from '../../components/LoaderComponent';
import DeleteMemberFromAppModal from './Components/DeleteMemberFromAppModal';
import {
  INVITE_MEMBER_IN_APPLICATION,
  REMOVE_APP_MEMBER
} from './graphql/Mutations';
import { GET_APP_MEMBER_GROUP_LIST } from './graphql/Queries';
import './style/appShare.less';

const AppShare = () => {
  const history = useHistory();
  const [form] = Form?.useForm();

  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [deleteMemberModalVisible, setDeleteMemberModalVisible] = useState(
    false
  );
  const [record, setRecord] = useState(null);
  const [recordData, setRecordData] = useState([]);
  const [selectedOne, setSelectedOne] = useState([]);
  const [selectedZero, setSelectedZero] = useState([]);

  const params = useParams();
  const { Option } = Select;

  const {
    state: { teamId }
  } = useContext(AppContext);

  const [getAppMembersGroupsList] = useLazyQuery(GET_APP_MEMBER_GROUP_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSelectedOne(
        res?.getAppMembersGroupsList?.getAppShareList?.filter(
          (val) => val?.selected === '1'
        )
      );
      setSelectedZero(
        res?.getAppMembersGroupsList?.getAppShareList?.filter(
          (val) => val?.selected === '0'
        )
      );
      setListLoading(false);
    }
  });

  useEffect(() => {
    getAppMembersGroupsList({
      variables: {
        data: {
          teamId,
          appId: params?.appId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, params?.appId]);

  const onSelectData = (value) => {
    const rec = find(selectedZero, (item) => item?.id === value);
    const recordDataCopy = [...recordData];
    recordDataCopy?.push({
      referenceId: value,
      referenceType: rec?.group_name ? 'GROUP' : 'TEAM_MEMBER'
    });
    setRecordData(recordDataCopy);
  };

  const onDeselectData = (id) => {
    const recordDataCopy = filter(
      recordData,
      (item) => item?.referenceId !== id
    );
    setRecordData(recordDataCopy);
  };

  const [shareApplication] = useMutation(INVITE_MEMBER_IN_APPLICATION, {
    onCompleted() {
      form?.resetFields();
    },
    onError(err) {
      setLoading(false);
      return err;
    }
  });

  const [removeAppMember] = useMutation(REMOVE_APP_MEMBER, {
    onError(err) {
      setLoading(false);
      return err;
    }
  });

  const handleSubmit = async (value) => {
    if (!value?.AddMemberInApplication?.length) {
      message?.error('Please Add Member(s)/Group(s)');
      return;
    }
    setLoading(true);
    try {
      const shareApp = await shareApplication({
        variables: {
          appId: params?.appId,
          data: recordData
        }
      });
      if (shareApp?.data) {
        setLoading(false);
        form?.resetFields();
        getAppMembersGroupsList({
          variables: {
            data: {
              teamId,
              appId: params?.appId
            }
          }
        });
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const handleDelete = async () => {
    setListLoading(true);
    setLoading(true);
    try {
      const removeFromApp = await removeAppMember({
        variables: {
          referenceId: record?.id,
          appId: params?.appId
        }
      });
      if (removeFromApp) {
        setLoading(false);
      }
      getAppMembersGroupsList({
        variables: {
          data: {
            teamId,
            appId: params?.appId
          }
        }
      });
    } catch (err) {
      setListLoading(false);
      setLoading(false);
      return err;
    }
  };

  const removeMemberModal = (values) => {
    setDeleteMemberModalVisible(true);
    setRecord(values);
  };

  const handleAddMember = () => {
    history?.push(ROUTES?.TEAMS);
  };

  return (
    <>
      <AppNameHeader />
      <div className="app-share-content d-flex justify-center ">
        <Card title="Share" className="app-share-card">
          <div>
            <div className="m-16">
              {AccessControl({
                allowedPermissions: ACTIONS?.ADD_APP_MEMBER
              }) && (
                <>
                  <Form
                    form={form}
                    name="addNewMembersInApplication"
                    layout="vertical"
                    onFinish={handleSubmit}
                  >
                    <Row>
                      <Col span={20}>
                        <Form.Item
                          className="add-member-in-team"
                          name="AddMemberInApplication"
                        >
                          <Select
                            placeholder={
                              <span className="d-flex align-center">
                                <SearchIcon className="mr-10" /> Search
                              </span>
                            }
                            mode="multiple"
                            optionLabelProp="label"
                            className="add-member-select"
                            onSelect={onSelectData}
                            onDeselect={onDeselectData}
                            filterOption={(input, option) => {
                              const filterData = get(
                                option,
                                'children.props.children.props.children.[1].props.children.props.children.[0].props.children',
                                ''
                              );
                              return filterData
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            dropdownRender={(menu) => (
                              <div className="app-share-dropdown">
                                <div>
                                  {menu}
                                  <div
                                    className="app-share-add-member"
                                    onClick={handleAddMember}
                                  >
                                    <img
                                      src={addMember}
                                      alt="add-member"
                                      width={32}
                                      className="mr-6"
                                    />
                                    <span className="font-500">
                                      Add New Members
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          >
                            {selectedZero?.map((details) => {
                              return (
                                <Option
                                  key={details?.id}
                                  value={details?.id}
                                  label={
                                    details?.first_name ||
                                    details?.invited_user_email ||
                                    details?.group_name
                                  }
                                >
                                  <div className="member-list-row">
                                    <Row className="app-share-member-row">
                                      <Col md={2} lg={2} xl={2} xxl={2}>
                                        <Avatar
                                          size={38}
                                          key={
                                            details?.first_name ||
                                            details?.group_name
                                          }
                                          style={
                                            !details?.profile_image &&
                                            (details?.first_name ||
                                              details?.invited_user_email)
                                              ? {
                                                  backgroundColor: avatarColor(
                                                    details?.first_name ||
                                                      details?.invited_user_email ||
                                                      details?.group_name
                                                  )
                                                }
                                              : {
                                                  backgroundColor: '#F2F4F7'
                                                }
                                          }
                                          src={details?.profile_image}
                                        >
                                          {details?.first_name
                                            ?.charAt(0)
                                            ?.toUpperCase() ||
                                            details?.invited_user_email
                                              ?.charAt(0)
                                              ?.toUpperCase() ||
                                            (details?.group_name && (
                                              <img
                                                src={groupIcon}
                                                alt="groupIcon"
                                                className="group-name-icon"
                                              />
                                            ))}
                                        </Avatar>
                                      </Col>
                                      <Col
                                        md={details?.role ? 16 : 22}
                                        lg={details?.role ? 16 : 22}
                                        xl={details?.role ? 16 : 22}
                                        xxl={details?.role ? 16 : 22}
                                      >
                                        <div className="member-list">
                                          <div className="name-tags">
                                            {details?.group_name ||
                                              trim(
                                                `${details?.first_name || ''} ${
                                                  details?.last_name || ''
                                                }`
                                              )}
                                          </div>
                                          <span className="font-12">
                                            {details?.invited_user_email}
                                          </span>
                                        </div>
                                      </Col>
                                      {details?.role && (
                                        <Col
                                          md={6}
                                          lg={6}
                                          xl={6}
                                          xxl={6}
                                          className="d-flex justify-end"
                                        >
                                          <div className="members-tag">
                                            <Tag className="br-50 font-10">
                                              {details?.role}
                                            </Tag>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  </div>
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item className="add-members">
                          <Button
                            className="share-app-button"
                            type="primary"
                            loading={loading}
                            htmlType="submit"
                            disabled={recordData?.length < 1}
                          >
                            Share
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Divider />
                </>
              )}
              <div className="share-text">Shared With</div>
              {listLoading ? (
                <LoaderComponent setHeight="500" />
              ) : (
                <div className="app-share-list">
                  {selectedOne?.map((value) => {
                    return (
                      <div key={value?.id}>
                        <Row className="row-hover">
                          <Col md={2} lg={2} xl={2} xxl={2}>
                            <Avatar
                              size={38}
                              shape="circle"
                              className="app-share-list-avatar"
                              style={
                                !value?.profile_image && {
                                  backgroundColor: avatarColor(
                                    value?.first_name ||
                                      value?.invited_user_email
                                  )
                                }
                              }
                              src={value?.profile_image}
                            >
                              {(value?.group_name && (
                                <img
                                  src={groupIcon}
                                  alt="groupIcon"
                                  className="group-name-icon"
                                />
                              )) ||
                                value?.first_name?.charAt(0)?.toUpperCase() ||
                                value?.invited_user_email
                                  ?.charAt(0)
                                  ?.toUpperCase()}
                            </Avatar>
                          </Col>
                          <Col md={15} lg={15} xl={15} xxl={15}>
                            {value?.group_name ? (
                              <div>
                                <span>{value?.group_name}</span>
                              </div>
                            ) : (
                              <div className="member-tag">
                                {value?.first_name ? (
                                  <div className="name-tags">
                                    {trim(
                                      `${value?.first_name || ''} ${
                                        value?.last_name || ''
                                      }`
                                    )}
                                  </div>
                                ) : (
                                  <Tag className="tag-bg">Invited</Tag>
                                )}
                                <span className="user-email">
                                  {value?.invited_user_email}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col md={5} lg={5} xl={5} xxl={5}>
                            {value?.invited_user_email && (
                              <Tag className="member-tag-role">
                                {value?.role}
                              </Tag>
                            )}
                          </Col>
                          <AccessControl
                            allowedPermissions={ACTIONS?.ADD_APP_MEMBER}
                          >
                            <Col
                              md={2}
                              lg={2}
                              xl={2}
                              xxl={2}
                              className="remove-col pr-5 pointer"
                            >
                              {value?.role !== 'OWNER' && (
                                <img
                                  src={deleteIcon}
                                  onClick={() => removeMemberModal(value)}
                                  alt="img"
                                  className="delete-icon"
                                />
                              )}
                            </Col>
                          </AccessControl>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </Card>
        <DeleteMemberFromAppModal
          setRecord={setRecord}
          record={record}
          setDeleteMemberModalVisible={setDeleteMemberModalVisible}
          deleteMemberModalVisible={deleteMemberModalVisible}
          handleDelete={handleDelete}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};

export default AppShare;
