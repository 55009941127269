import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import nextButtonArrow from '../../assets/svg/next-button-arrow.svg';
import rightArrow from '../../assets/svg/right-arrow.svg';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { formValidatorRules, googleAnalyticsEvents } from '../../common/utils';
import { INVITE_MEMBERS } from './graphql/mutation';
import './style/inviteMembers.less';

const InviteMembers = () => {
  const [loading, setLoading] = useState(false);
  const [initialValue] = useState({
    names: [{ email: null, role: null }]
  });
  const {
    state: { teamId }
  } = useContext(AppContext);

  const [inviteMemberInTeam] = useMutation(INVITE_MEMBERS, {
    onError(error) {
      setLoading(false);
      return error;
    }
  });

  const history = useHistory();

  const redirectToNextPath = () => {
    googleAnalyticsEvents(GA_EVENT?.SKIP_ONBOARDING_INVITE_MEMBER, {
      label: GA_LABEL?.SKIP_ONBOARDING_INVITE_MEMBER
    });
    const path = ROUTES?.APPS;
    history?.push(path);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const inviteMemberInTeamResponse = await inviteMemberInTeam({
      variables: {
        teamId,
        members: values?.names
      }
    });
    try {
      if (inviteMemberInTeamResponse?.data) {
        setLoading(false);
        redirectToNextPath();
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  if (teamId === '') {
    history?.push(ROUTES?.APPS);
  }

  const { required, email } = formValidatorRules;

  return (
    <Row
      justify="center"
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      className="mt-35"
    >
      <div className="invite-container">
        <Row justify="space-between" align="middle">
          <Col span={20}>
            <div className="invite-head">
              <span className="invite-member">Invite Team Members</span>
              <span className="line-26">
                Share your apps with your team faster
              </span>
            </div>
          </Col>
          <Col span={4} flex="0">
            <div className="skip" onClick={redirectToNextPath}>
              <span>Skip</span>
              <img src={rightArrow} alt="right-arrow" />
            </div>
          </Col>
        </Row>
        <div className="form-wrapper-data mt-24">
          <Form
            name="dynamic_form_item"
            initialValues={initialValue}
            onFinish={handleSubmit}
            className="form"
          >
            <Form.List name="names">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key} className="d-flex">
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        rules={[{ ...required, ...email }]}
                      >
                        <Input
                          placeholder="Email"
                          name="email"
                          className="email-input"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        rules={[{ ...required }]}
                        className="role-input"
                      >
                        <Select placeholder="Role" name="role" allowClear>
                          <Select.Option value="ADMIN">Admin</Select.Option>
                          <Select.Option value="DEVELOPER">
                            Developer
                          </Select.Option>
                          <Select.Option value="TESTER">Tester</Select.Option>
                        </Select>
                      </Form.Item>
                      {fields?.length > 1 && (
                        <>
                          <span
                            onClick={() => {
                              remove(name);
                            }}
                            className="remove-btn"
                          >
                            <MinusCircleOutlined />
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                  <Row align="middle">
                    <Col span={24}>
                      <div className="add-more-members">
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add More
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>

            <div className="send-invitation d-flex justify-center align-center b-8">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="invitation-btn"
                  >
                    Send Invitation
                    {!loading && (
                      <img
                        src={nextButtonArrow}
                        alt="next-button-arrow"
                        className="ml-4"
                      />
                    )}
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Row>
  );
};

export default InviteMembers;
